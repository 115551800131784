import {CustomElement} from "../custom-element";
import NamaTab from "./tab.atom";
import NamaModal from "../molecules/modal.molecule";
import Widget from "../../controllers/widget.controller";
import NamaWebview from "./webview.atom";
import isUndefined from "lodash/isUndefined";
import get from "lodash/get";
import NamaChatBox from "../organisms/chat.box.organism";

@CustomElement({
  selector: 'nama-button'
})
export default class NamaButton extends HTMLElement{

    public href:Attr | any;
    public target:Attr | any;
    public webview_height_ratio?:Attr;

    constructor(href?:string, target?:string, webview_height_ratio?:string){
      super();
      if(!isUndefined(href)){
        let hrefAttr = document.createAttribute('href');
        hrefAttr.value = href;
        this.attributes.setNamedItem(hrefAttr);
      }
      if(!isUndefined(target)){
        let targetAttr = document.createAttribute('target');
        targetAttr.value = target;
        this.attributes.setNamedItem(targetAttr);
      }
      if(!isUndefined(webview_height_ratio)){
        this.webview_height_ratio = document.createAttribute('webview_height_ratio');
        this.webview_height_ratio.value = webview_height_ratio;
        this.attributes.setNamedItem(this.webview_height_ratio);
      }
    }

    connectedCallback() {
      this.href = this.attributes.getNamedItem('href');
      this.target = this.attributes.getNamedItem('target');
      this.addEventListener('click' , (e:any) => {
        e.stopPropagation();
        this.click();
      });

      if(this.target !== null){
        if(location.hash !== "" && this.target.value === '_tab'){
          if(location.hash === `#${this.href.value}` ){
            this.deactiveAllBrothers();
          }
        }
      }
    }

    deactiveAllBrothers(){
      if(this.parentElement){
        let tabQuery = this.parentElement.querySelectorAll("nama-button");
        for (let tab = 0; tab <= tabQuery.length; tab++) {
          let tabEl = tabQuery[tab] as NamaTab;
          if(tabEl){
            tabEl.classList.remove("active");
          }
        }
      }
      this.classList.add("active");
    }

    click() {
      if(this.href !== null){
        if(this.target == null){
          location.href = this.href.value;
        } else if(this.target.value === '_blank') {
          let win = window.open(this.href.value, '_blank');
          if(win !== null){
            win.focus();
          }
        } else if(this.target.value === '_tab') {
          let istanceTab:NamaTab | null = document.querySelector('#'+ this.href.value);
            if(istanceTab){
              istanceTab.show();
              this.deactiveAllBrothers();
            }
        } else if(this.target.value === '_submit') {
          let form = document.querySelector(this.href.value);
          if(form !== null){
            (form as any).submit();
          }
        } else if(this.target.value === '_modal') {
            let istanceModal:NamaModal | null = document.querySelector('#'+ this.href.value);
            if(istanceModal){
              istanceModal.show();
            }
        } else if(this.target.value === '_modal_close') {
          let istanceModal:NamaModal | null = document.querySelector('#'+ this.href.value);
          if(istanceModal){
            istanceModal.close();
          }
        } else if(this.target.value === '_talk') {
          let namaWidget:Widget | null = document.querySelector('nama-widget');
          if(namaWidget && !isUndefined(namaWidget.sdk)){
            if(this.href.value){
              namaWidget.sdk.send(this.href.value);
            } else {
              namaWidget.sdk.send(this.innerHTML);
            }
          }
          this.focusTextarea();
        } else if(this.target.value === '_talk_action') {
          let namaWidget:Widget | null = document.querySelector('nama-widget');

          let bot_id = get(namaWidget, 'sdk.user.client.bot_id');
          if(bot_id && namaWidget && !isUndefined(namaWidget.sdk)) {
            namaWidget.sdk.action(bot_id, this.href.value);
          }
          this.focusTextarea();
        } else if(this.target.value === '_webview') {
          let namaChat:NamaChatBox | null = document.querySelector('nama-chat-box');
          let webview_height_ratio = (!isUndefined(this.webview_height_ratio)) ? this.webview_height_ratio.value : undefined;
          let namaWebview:NamaWebview = new NamaWebview(this.href.value, webview_height_ratio);
          if(namaChat){
            namaChat.appendChild(namaWebview);
          }
        }

      }
    }

    private focusTextarea() {
      let namaChatBox:NamaChatBox | null = document.querySelector('nama-chat-box');
      if(namaChatBox) {
        namaChatBox.input.input.focus();
      }
    }
}
