import {CustomElement} from "../custom-element";

@CustomElement({
  selector: 'nama-widget-header'
})
export default class NamaWidgetHeader extends HTMLElement{

  public close:HTMLButtonElement;
  public minimize:HTMLButtonElement;

  constructor(public widgetTitle:string, private noClose:boolean){
    super();
    this.close = this.createActionButton('close')
    this.minimize = this.createActionButton('minimize');
  }

  public render(title:string, type:string){
    return `
      <div class="header-title">
        ${this.renderTitle(title, type)}
      </div>
      <div class="header-actions"></div>
    `
  }

  public renderTitle(title:string, type:string){
    return `
        <h3>${title}</h3>
        <span>${type}</span>
    `
  }


  public setOperator(name:string, avatar?:string){
    let headerTitleEl = this.querySelector('.header-title');
    if(headerTitleEl){
      headerTitleEl.innerHTML = this.renderTitle(name, 'Operador Humano');
    }
  }

  public connectedCallback() {
    this.innerHTML = this.render(this.widgetTitle, 'Assistente Virtual');
    let headerActionsEl = this.querySelector('.header-actions');
    if (headerActionsEl) {
      if(this.noClose === false){
        headerActionsEl.appendChild(this.close);
        headerActionsEl.appendChild(this.minimize);
      }
    }
  }

  private createActionButton(id:string):HTMLButtonElement {
    let element = document.createElement('button');
    element.id = "nama-" + id;
    if (id === 'close') {
      element.innerHTML = this.renderCloseSVG()
    } else if (id === 'minimize') {
      element.innerHTML = this.renderMinimizeSVG()
    }
    return element;
  }

  private renderCloseSVG():string {
    return `
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <path d="M12.051,10.637 L14.707,13.293 C15.098,13.684 15.098,14.317 14.707,14.707 C14.512,14.903 14.256,15 14,15 C13.744,15 13.488,14.903 13.293,14.707 L10.668,12.082 C10.299,11.713 9.701,11.713 9.332,12.082 L6.707,14.707 C6.512,14.903 6.256,15 6,15 C5.744,15 5.489,14.903 5.293,14.707 C4.903,14.317 4.903,13.684 5.293,13.293 L7.887,10.699 C8.273,10.314 8.273,9.687 7.887,9.301 L5.293,6.707 C4.903,6.317 4.903,5.684 5.293,5.293 C5.684,4.903 6.317,4.903 6.707,5.293 L9.301,7.887 C9.687,8.273 10.313,8.273 10.699,7.887 L13.293,5.293 C13.684,4.903 14.317,4.903 14.707,5.293 C15.098,5.684 15.098,6.317 14.707,6.707 L12.051,9.364 C11.699,9.715 11.699,10.285 12.051,10.637 M10,0 C4.477,0 0,4.478 0,10 C0,15.523 4.477,20 10,20 C15.523,20 20,15.523 20,10 C20,4.478 15.523,0 10,0" id="Fill-1"></path>
      </svg>
    `;
  }

  private renderMinimizeSVG():string {
    return `
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <path d="M14,11 L6,11 C5.447,11 5,10.553 5,10 C5,9.447 5.447,9 6,9 L14,9 C14.553,9 15,9.447 15,10 C15,10.553 14.553,11 14,11 M10,0 C4.478,0 0,4.478 0,10 C0,15.522 4.478,20 10,20 C15.522,20 20,15.522 20,10 C20,4.478 15.522,0 10,0" id="Page-1"></path>
      </svg>
    `;
  }
}


