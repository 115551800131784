import {CustomElement} from "../custom-element";
import NamaButton from "../atoms/button.atom";
import { isUndefined } from "util";
import Widget from "../../controllers/widget.controller";

@CustomElement({
  selector: 'nama-chat-disclaimer'
})
export default class NamaChatDisclaimer extends HTMLElement{
  public beginButton?:NamaButton;
  public closeButton?:NamaButton;
  private disclaimerContainer?:HTMLElement;

  constructor(public disclaimerText: string) {
    super();
    if(disclaimerText) {
      this.disclaimerContainer = document.createElement('nama-disclaimer-container');
      this.createMainHTML(disclaimerText);
    }
  }

  connectedCallback(){
    this.bind();
  }

  private createMainHTML(disclaimerText: string) {
    let disclaimer = document.createElement('nama-disclaimer');
    disclaimer.innerHTML = disclaimerText.replace(/(?:\r\n|\r|\n)/g, '<br>');
    if(!isUndefined(this.disclaimerContainer)) {
      this.disclaimerContainer.appendChild(disclaimer);
      this.appendChild(this.disclaimerContainer);
      this.appendChild(this.createButtonsHTML());
    }
  }

  private createButtonsHTML():HTMLElement {
    let disclaimerButtons = document.createElement('nama-disclaimer-buttons');
    this.closeButton = new NamaButton();
    this.closeButton.innerHTML = 'Fechar';

    this.beginButton = new NamaButton();
    this.beginButton.classList.add('primary');
    this.beginButton.innerHTML = 'Começar';

    disclaimerButtons.appendChild(this.closeButton);
    disclaimerButtons.appendChild(this.beginButton);
    return disclaimerButtons;
  }

  private bind(){
    if(!isUndefined(this.closeButton)){
      this.closeButton.addEventListener('click', () => {
        this.refuseDisclaimer();
      });
    }
    if(!isUndefined(this.beginButton)){
      this.beginButton.addEventListener('click', () => {
        this.acceptDisclaimer();
      });
    }
  }

  private refuseDisclaimer(){
    let namaWidget:Widget | null = document.querySelector('nama-widget');
    if(namaWidget){
      namaWidget.close();
    }
  }

  private acceptDisclaimer(){
    this.classList.add('nama-hide');
    let namaWidget:Widget | null = document.querySelector('nama-widget');
    if(namaWidget && namaWidget.sdk && namaWidget.sdk.user){
      namaWidget.sdk.user.acceptDisclaimer();
      if(namaWidget.box && namaWidget.box.input){
        namaWidget.box.input.input.focus();
      }
      setTimeout(() => {
        this.remove();
        this.classList.remove('nama-hide');
      }, 400);
    }
  }
}
