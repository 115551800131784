import isUndefined from "lodash/isUndefined";
import { AxiosPromise } from 'axios';
import axios from 'axios';
import Chat from "../models/chat.model";
import sanitizeHtml from 'sanitize-html';

declare let MessengerExtensions: any;
const qs = require('qs');

/*
* The PlatformApiService Class
* Connect to send and load messeges from nama api
* @author  Leonardo 'Alemax' Ferreira (leonardo.ferreira@nama.ai)
* @version 1.0
* @since   2018-03-09
*/
export default class PlatformApi {

  constructor(public apiToken:string,
              public serverUrl:string,
              public userId:string) {
  }

  public send(text: string,
              sensitive_information?: any,
              metadata?: any): AxiosPromise {

    const url: string = `${this.serverUrl}/web/hooks/${this.apiToken}`;
    // prepere data
    let clean = sanitizeHtml(text);
    let data = {
      user: this.userId,
      text: clean,
      metadata: metadata,
      sensitive_information: sensitive_information
    };

    return axios.post(url, qs.stringify(data))
  }

  public send_event(text:string, event_name:string): AxiosPromise {
    let clean = sanitizeHtml(event_name);
    return this.send(text, undefined, {
      event: clean,
      process_bot: false,
      system_message: true
    });
  }

  public load(recent_than?:string): AxiosPromise {
    // prepare update chat url to request
    recent_than = isUndefined(recent_than) ? "-1" : recent_than;
    let url: string = `${this.serverUrl}/web/messages/${this.apiToken}`;
    url += `?recent_than=${recent_than}`;
    url += `&user=${this.userId}`;

    return axios.get(url);
  }

  public action(bot_id: string,
                action: string,
                channel_identifier?: string) : AxiosPromise {

    let url = `${this.serverUrl}/api/v1/bots/${bot_id}/send_message`;

    if (!isUndefined(channel_identifier)) {
      (function (d, s, id) {
        let js:any;
        let fjs:any = d.getElementsByTagName(s)[0];
        js = d.createElement(s);
        js.id = id;
        js.src = "//connect.facebook.com/en_US/messenger.Extensions.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'Messenger'));
    }
    let clean = sanitizeHtml(action);
    let data = {
      function: clean,
      user_identifier: this.userId,
      channel: isUndefined(channel_identifier) ? "Web" : "FacebookPage",
      channel_identifier: channel_identifier || '',
    };

    let config = {
       headers: {
        authorization: "Token token="+this.apiToken
       }
    };
    return axios.post(url, qs.stringify(data), config);
  }

  public postback(): AxiosPromise {
    let url: string = `${this.serverUrl}/api/v1/chats/`;
        url += `${this.userId}/third_party_authenticate`;
    let data = {
      chat: {
        key: "when_webview_closed"
      }
    };
    let config = {
       headers: {
         authorization: "Token token=e796500e142b4b7098859a1747102a1d"
       }
    };
    return axios.put(url, data, config);
  }

  public close_by_user(): AxiosPromise {
    const url: string = `${this.serverUrl}/api/v1/chats/closed_by_user/${this.userId}`;
    return axios.post(url, {});
  }
}
