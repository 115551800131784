import {CustomElement} from "../custom-element";
import isUndefined from "lodash/isUndefined";
import NamaButton from '../atoms/button.atom';
import NamaEmoji from "../atoms/emoji.atom";

@CustomElement({
  selector: 'nama-choices'
})
export default class NamaChoices extends HTMLElement{
  private seeMoreButton: HTMLAnchorElement;
  private seeMore_Event: EventListener | undefined; // to use in removeEventListener

  constructor(public choices?:Array<NamaButton>, public action?:any){
    super();
    this.seeMoreButton = document.createElement('a');
  }

  connectedCallback() {
    if(!isUndefined(this.choices)){
      this.choices.forEach((choice:any) => {
        let button_type;
        let href;
        if (choice.type === 'web_url') {
          button_type = (choice.force_webview) ? '_webview' : '_blank';
          href = choice.url;
        } else {
          button_type = '_talk';
          href = choice.value;
        }

        let btn = new NamaButton(href, button_type, choice.webview_height_ratio);
        btn.classList.add("small");
        btn.innerHTML = new NamaEmoji(choice.value).parse();
        this.appendChild(btn);
      });
    }
    this.addSeeMoreButton();
  }

  private addSeeMoreButton() {
    if(this.offsetHeight >= 100) {
      this.seeMoreButton.id = 'nama-choices-see-more'
      this.seeMoreButton.innerText = 'Mostrar mais';
      this.appendChild(this.seeMoreButton);
      this.bindSeeMoreButton();
    }
  }

  private bindSeeMoreButton(already_maximized:Boolean = false) {
    if(!isUndefined(this.seeMore_Event)){
      this.seeMoreButton.removeEventListener('click', this.seeMore_Event);
    }
    if(already_maximized){
      this.seeMore_Event = () => this.restore();
    } else {
      this.seeMore_Event = () => this.maximize();
    }
    this.seeMoreButton.addEventListener('click', this.seeMore_Event);
  }

  private maximize() {
    this.classList.add('choices-maximized');
    this.seeMoreButton.innerText = 'Mostrar menos';
    this.bindSeeMoreButton(true);
  }

  private restore() {
    this.classList.remove('choices-maximized');
    this.seeMoreButton.innerText = 'Mostrar mais';
    this.bindSeeMoreButton();
  }
}
