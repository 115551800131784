import Message from "./message.model";
import orderBy from "lodash/orderBy";
import dropRight from "lodash/dropRight";
import reject from "lodash/reject";
import last from "lodash/last";
import findIndex from "lodash/findIndex";
import { CustomElement } from "../patterns/custom-element";
import isUndefined from "lodash/isUndefined";
import uniqBy from "lodash/uniqBy";
import moment from "moment";
import map from "lodash/map";
import find from "lodash/find";
import assign from "lodash/assign";
import unionBy from "lodash/unionBy";
import filter from "lodash/filter";
import User from "../classes/user.class";
import Operator from "./operator.model";
import LocalStorageService from "../services/local.storage.service";

@CustomElement({
  selector: "nama-messages-model"
})
export default class Messages extends HTMLElement {
  public list: Array<Message> = [];
  public localStorageService?: LocalStorageService;

  constructor(public setDates?: boolean, public user?: User, public user_identifier?: string) {
    super();

    if (!isUndefined(user_identifier)) {
      let storage_prefix = `nama-${user_identifier}-`;
      this.localStorageService = new LocalStorageService(storage_prefix);

      let storedMessages: Array<Message> | undefined = this.localStorageService.get(
        "messages",
        true
      );
      if (!isUndefined(storedMessages)) {
        this.list = storedMessages;
      }
    }
  }

  public resetStorage(user_identifier: string) {
    if (!isUndefined(user_identifier)) {
      let storage_prefix = `nama-${user_identifier}-`;
      this.localStorageService = new LocalStorageService(storage_prefix);
    }
  }

  public send(text: string, user: any, sent_by_user: boolean, metadata?: any) {
    if (text !== "") {
      let message = new Message({
        id: (+new Date()).toString(36),
        user: user,
        text: text,
        is_temp: true,
        sent_by_user: sent_by_user,
        metadata: metadata,
        visualized: false
      });
      this.add([message]);
      const event = document.createEvent("CustomEvent");
      event.initCustomEvent("send", false, false, text);
      this.dispatchEvent(event);
    }
  }

  public add(messages: Array<Message>, onboarding_message?: boolean) {
    if (messages.length > 0) {
      if (onboarding_message) {
        messages[0].type = "onboarding";
      }
      let parsed = this.parse(messages);
      let unioned = unionBy(this.list, parsed, (m: Message) => m.id);

      this.list = unioned;

      if (this.setDates) {
        this.dates();
      }
      this.order();

      if (!isUndefined(this.user)) {
        this.user.client.bot_id = this.extractBotID(parsed);
      }

      this.triggers();

      var eventAdd = document.createEvent("CustomEvent");
      eventAdd.initCustomEvent("add", false, false, this.list);
      this.dispatchEvent(eventAdd);

      let lastMessage = last(messages);

      if (
        lastMessage &&
        lastMessage.choices != null &&
        lastMessage.choices.length > 0
      ) {
        var eventChoices = document.createEvent("CustomEvent");
        eventChoices.initCustomEvent("choices", false, false, lastMessage.choices);
        this.dispatchEvent(eventChoices);
      }
      this.updateStorage();
    }
  }

  public updateStorage(): void {
    if (this.localStorageService) {
      this.localStorageService.set("messages", JSON.stringify(this.list));
    }
  }

  public find(id: string): number {
    return findIndex(this.list, (message: Message) => {
      return message.id === id;
    });
  }

  private confirm(message: Message): boolean {
    if (isUndefined(message.id)) {
      return false;
    }
    let index = 0;
    for (let oldMessage of this.list) {
      if (oldMessage.is_temp) {
        if (oldMessage.text === message.text) {
          this.list[index] = message;
          return true;
        }
      }
      index++;
    }
    return false;
  }

  private parse(messages: Array<any>): Array<Message> {
    let parsed: Array<Message> = [];
    messages.forEach((message: any) => {
      message = new Message(message);
      if (!isUndefined(message.metadata.operator_name)) {
        var eventChoices = document.createEvent("CustomEvent");
        eventChoices.initCustomEvent("operator:change", false, false, message.metadata);
        this.dispatchEvent(eventChoices);
      }
      if (message.text !== "") {
        if (!this.confirm(message)) {
          parsed.push(message);
        }
      }
    });
    return parsed;
  }

  private order() {
    this.list = orderBy(this.list, ["created_at", "is_system"], ["asc", "desc"]);
    this.list = orderBy(this.list, ["is_temp"], ["asc"]);
    this.list = orderBy(this.list, message => message.type === "onboarding", ["desc"]);
  }

  private addDateMessage(data: any, type: string) {
    if (isUndefined(data)) {
      data = {
        date: this.list[0].created_at,
        id: this.list[0].id
      };
    }

    let message = new Message({
      id: `date-${type}-${data.id}`,
      type: "date",
      text: data.date,
      is_temp: false,
      is_system: true,
      created_at: data.date,
      sent_by_user: false,
      metadata: {
        date_type: type
      },
      visualized: false,
      notified: false,
      triggered: false
    });
    this.list.push(message);
  }

  private lastDay() {
    let days = filter(this.list, (o: Message) => {
      return o.is_system && o.metadata.date_type === "day";
    });
    let lastDay = last(days);
    if (lastDay) {
      return {
        id: lastDay.id,
        date: moment(lastDay.created_at)
          .minutes(0)
          .seconds(0)
          .format()
      };
    } else {
      return undefined;
    }
  }

  private lastMinute() {
    let minutes = filter(this.list, (o: Message) => {
      return o.is_system && o.metadata.date_type === "minute";
    });
    let lastMinutes = last(minutes);
    if (lastMinutes) {
      return {
        id: lastMinutes.id,
        date: moment(lastMinutes.created_at)
          .seconds(0)
          .format()
      };
    } else {
      return undefined;
    }
  }

  private dates() {
    let lastDay = this.lastDay();
    let lastMinute = this.lastMinute();

    if (isUndefined(lastDay)) {
      this.addDateMessage(lastDay, "day");
    }

    if (isUndefined(lastMinute)) {
      this.addDateMessage(lastMinute, "minute");
    }

    this.list.forEach((message: Message) => {
      if (!message.is_temp) {
        let lastDay = this.lastDay();
        if (lastDay) {
          let lastDayParsed = moment(lastDay.date)
            .hours(0)
            .minutes(0)
            .seconds(0)
            .format();
          let thisDay = moment(message.created_at)
            .hours(0)
            .minutes(0)
            .seconds(0)
            .format();
          if (new Date(lastDayParsed) < new Date(thisDay)) {
            this.addDateMessage(
              {
                id: message.id,
                date: message.created_at
              },
              "day"
            );
          }
        }

        let lastMinute = this.lastMinute();
        if (lastMinute) {
          let lastMinuteParsed = moment(lastMinute.date)
            .seconds(0)
            .format();
          let thisMinute = moment(message.created_at)
            .seconds(0)
            .format();
          if (new Date(lastMinuteParsed) < new Date(thisMinute)) {
            this.addDateMessage(
              {
                id: message.id,
                date: message.created_at
              },
              "minute"
            );
          }
        }
      }
    });
  }

  private triggers() {
    this.list.forEach((message: Message) => {
      if (!message.triggered && !isUndefined(message.triggers)) {
        for (let trigger in message.triggers) {
          // console.log('message to dispatch', message);
          var triggersEvent = document.createEvent("CustomEvent");
          triggersEvent.initCustomEvent("triggers", false, false, {
            name: trigger,
            arguments: message.triggers[trigger]
          });

          this.dispatchEvent(triggersEvent);
        }
        message.triggered = true;
      }
    });
  }

  private extractBotID(messages: Array<Message>): string | undefined {
    let bot_id;
    messages.forEach((message: Message) => {
      if (!isUndefined(message.from) && message.from !== "") {
        bot_id = message.from;
      }
    });
    return bot_id;
  }
}
