export default class LocalStorageService {
  public key_prefix: string;
  private isAvailable: boolean;

  /**
   * LocalStorageService
   * @public isAvailable:boolean -> If is true, you can use LocalStorage
   */
  constructor(key_prefix: string = "nama-") {
    this.key_prefix = key_prefix;
    this.isAvailable = this.localStorageIsAvailable();
  }

  /**
   * Set localStorage Item.
   */
  public set(key: string, value: any) {
    if (this.isAvailable) {
      return localStorage.setItem(this.key_prefix + key, value);
    } else {
      console.info("You are trying to access a not available localstorage");
    }
  }

  /**
   * Get localStorage Field by key.
   */
  public get(key: string, parseJSON?: boolean) {
    if (this.isAvailable) {
      let item = localStorage.getItem(this.key_prefix + key);
      if (item) {
        return parseJSON ? JSON.parse(item) : item;
      }
    } else {
      console.info("You are trying to access a not available localstorage");
    }
  }

  /**
   * Remove localStorage Field by key.
   */
  public remove(key: string) {
    if (this.isAvailable) {
      localStorage.removeItem(this.key_prefix + key);
    } else {
      console.info("You are trying to access a not available localstorage");
    }
  }

  /**
   * Check localStorage availability
   * @returns boolean
   */
  public localStorageIsAvailable(): boolean {
    if (typeof localStorage !== "undefined") {
      try {
        localStorage.setItem("feature_test", "yes");
        if (localStorage.getItem("feature_test") === "yes") {
          localStorage.removeItem("feature_test");
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    } else {
      return false;
    }
  }
}
