import Client from '../models/client.model';
import LocalCookies from '../services/local.cookies.service';

export default class User {

  public cookies: LocalCookies;
  public client: Client;
  public userUniqId: string;

  constructor(public cname?: any, userUniqId?: string , public domain?: string, public singleUse?:boolean) {
    this.cookies = new LocalCookies(this.domain);
    this.userUniqId = userUniqId || Math.random().toString(36).slice(2);

    if (this.cookies.hasItem(cname) && !singleUse) {
      let hash = this.cookies.getItem(this.cname) + "";
      let cookie = JSON.parse(hash);
      this.client = new Client(cookie.id, cookie.hasInteract , cookie.cname, cookie.domain);
      this.userUniqId = this.client.id;
    } else {
      this.client = new Client(this.userUniqId, false , this.cname, this.domain);
    }

    this.save();
  }

  public disclaimerCookie(){
    return this.cookies.getItem('nama-user-accepted-disclaimer');
  }

  public acceptDisclaimer(){
    this.cookies.setItem('nama-user-accepted-disclaimer', true, 604800);
  }

  public save(){
    let expiration = 604800;
    this.cookies.setItem(this.cname, JSON.stringify(this.client), expiration);
  }

  public clean(){
    this.cookies.removeItem('nama-user-accepted-disclaimer');
    this.cookies.removeItem(this.cname);
    this.client = new Client(Math.random().toString(36).slice(2), false , this.cname, this.domain);
    this.save();
  }
}
