import Sdk from "./sdk.controller";
import WidgetOptions from "../models/options.widget.model";
import OptionsHelper from "../classes/options.helper.class";
import merge from "lodash/merge";
import get from "lodash/get";
import isUndefined from "lodash/isUndefined";
import { CustomElement } from "../patterns/custom-element";
import NamaChatBox from "../patterns/organisms/chat.box.organism";
import NamaWidgetLauncher from "../patterns/organisms/widget.launcher.organism";
import NamaChatDisclaimer from "../patterns/organisms/chat.disclaimer.organism";
// import style from '../patterns/widget.scss';

@CustomElement({
  selector: "nama-widget"
})
export default class Widget extends HTMLElement {
  public options?: WidgetOptions;
  public sdk?: Sdk;
  public box?: NamaChatBox;
  public launcher?: NamaWidgetLauncher;
  public optionsHelper: OptionsHelper;
  public disclaimer?: NamaChatDisclaimer;
  public currentStatus: string | undefined;

  constructor(options: any) {
    super();
    this.optionsHelper = new OptionsHelper();
    console.log('widget contructor');
    if (!isUndefined(options)) {
      this.disclaimer = new NamaChatDisclaimer(options.disclaimer);
      this.options = new WidgetOptions(options || {});
      this.instance();
      if (typeof this.options.onboarding.text !== "undefined") {
        this.setOnboarding(this.options.onboarding);
      }
    }
  }

  public loadStyle(color?: string) {
    let css = require("../patterns/widget.scss");
    if (!isUndefined(color)) {
      css = css.toString().replace(/\#2962FF/g, color);
    }
    let style = document.createElement("style");
    style.innerHTML = css;

    let head = document.querySelector("head");
    if (head) {
      head.appendChild(style);
    }
  }

  public instance() {
    if (this.options) {
      this.loadStyle(this.options.color);

      this.sdk = new Sdk(this.options);
      if (!isUndefined(this.sdk.messages) && !isUndefined(this.sdk.user)) {
        this.sdk.messages.resetStorage(this.sdk.user.userUniqId);
      }

      this.box = new NamaChatBox(this.options, this.sdk.messages, this.sdk.send);
      if (typeof this.options.onboarding.text !== "undefined") {
        this.setOnboarding(this.options.onboarding);
      }
      this.launcher = new NamaWidgetLauncher(this.options, this.sdk.messages);
    }
  }

  public setOptions(options: any) {
    this.close();
    this.options = new WidgetOptions(options || {});
    this.disclaimer = new NamaChatDisclaimer(options.disclaimer);
    this.start();
  }

  reset() {
    if (this.sdk && this.sdk.user) {
      this.close();
      if (this.sdk.messages && this.sdk.messages.localStorageService) {
        this.sdk.messages.localStorageService.remove("messages");
      }
      this.sdk.user.clean();
      this.setOptions(this.options);
    }
  }

  public setOnboarding(message: any) {
    this.sdk!.messages!.add([message], true);
  }

  public start() {
    this.innerHTML = "";
    this.instance();
    this.close();
    if (this.launcher && this.box && this.sdk) {
      this.appendChild(this.box);
      this.appendChild(this.launcher);

      this.box.input.form.addEventListener("submit", (evt: any) => {
        evt.preventDefault();
        if (this.box && this.sdk) {
          if (this.box.input.input.value !== "") {
            this.sdk.send(this.box.input.input.value);
            this.box.input.input.value = "";
          }
        }
      });

      if (this.options) {
        if (this.options.behavior === "default") {
          if (this.sdk && this.sdk.user && this.sdk.user.client.hasInteract) {
            this.engaged();
          } else {
            this.startLauncher();
          }
        } else if (this.options.behavior === "always-open") {
          this.engaged();
          if (
            !(location.host === "dashboard.nama.ai" || location.host === "bb:3000" || location.host === "app.staging.nama.ai")
          ) {
            this.box.header.minimize.style.display = "none";
          }
        } else if (this.options.behavior === "after-interact-open") {
          if (this.sdk && this.sdk.user && this.sdk.user.client.hasInteract) {
            this.engaged();
          } else {
            this.startLauncher();
          }
        } else {
          this.startLauncher();
        }
        if (typeof this.options.onboarding.text !== "undefined") {
          this.setOnboarding(this.options.onboarding);
        }
      }
      this.bind();
    }
    console.log('widget disclamer binder');
    if (!isUndefined(this.disclaimer) && this.sdk && this.sdk.user && !this.sdk.user.disclaimerCookie()) {
      if (this.disclaimer.innerHTML !== "") {
        this.appendChild(this.disclaimer);
      }
    }
  }

  bind() {
    let close = (e: any) => {
      e.preventDefault();
      this.close(true);
    };
    let open = () => {
      if (this.box && this.box.chat) {
        this.box.chat.removeEventListener("click", open, false);
        this.open(true);
      }
    };
    let focus = () => {
      this.removeEventListener("click", open, false);
      this.engaged(true);
    };
    let shutdown = (e: any) => {
      e.preventDefault();
      this.shutdown();
    };
    if (this.box && this.box.chat && this.launcher) {
      this.box.chat.addEventListener("click", open, false);
      this.box.input.input.addEventListener("focus", focus, false);
      this.launcher.addEventListener("click", open, false);
      this.box.header.minimize.addEventListener("click", close, false);
      this.box.header.close.addEventListener("click", shutdown, false);
      this.box!.messages!.addEventListener("triggers", (event: any) => {
        console.log(
          "%c[Nama] Trigger Event Detail Dispatched:",
          "background: #252729; color: #fff",
          event.detail
        );
        const triggersEvent = document.createEvent("CustomEvent");
        triggersEvent.initCustomEvent("triggers", false, false, event.detail);
        this.dispatchEvent(triggersEvent);
      });
    }
  }

  clear() {
    this.classList.remove("closed", "full", "minimized", "hovered", "avatared", "engaged");
  }

  close(sendEvent: boolean = false) {
    if (this.box && this.launcher && this.currentStatus !== "minimized") {
      this.clear();
      this.classList.add("closed");
      this.box.close();
      this.style.height = "0px";
      let hasInteract: boolean = get(this, "sdk.user.client.hasInteract", false);
      let showOnboarding = hasInteract ? false : true;
      this.launcher.start(showOnboarding);
      if (this.sdk && this.sdk.server && sendEvent) {
        this.sdk.server.send_event("Usuário minimizou o chat", "chat_minimized");
      }

      this.currentStatus = "minimized";
      const minimizedEvent = document.createEvent("CustomEvent");
      minimizedEvent.initCustomEvent("widget_minimized", false, false, {});
      this.dispatchEvent(minimizedEvent);
    }
  }

  shutdown() {
    if (confirm("Deseja realmente encerrar esta conversa?")) {
      if (!isUndefined(this) && !isUndefined(this.sdk) && !isUndefined(this.sdk.user)) {
        if (this.sdk.server && this.sdk.isConnected()) {
          this.sdk.server.close_by_user();
        }
        this.sdk.user.clean();
      }
      if (this.sdk && this.sdk.messages && this.sdk.messages.localStorageService) {
        this.sdk.messages.localStorageService.remove("messages");
      }
      if (this.sdk && this.sdk.server) {
        this.sdk.server.send_event("Usuário fechou o chat", "chat_closed");
      }
      this.close();
      const shutdownEvent = document.createEvent("CustomEvent");
      shutdownEvent.initCustomEvent("widget_shutdown", false, false, {});
      this.dispatchEvent(shutdownEvent);
      this.start();
    }
  }

  hover(sendEvent: boolean = false) {
    if (this.box && this.launcher && this.currentStatus !== "hovered") {
      this.box.hover();
      this.clear();
      this.classList.add("avatared");
      this.launcher.pause();
      this.style.height = null;
      this.currentStatus = "hovered";
      if (this.sdk && this.sdk.server && sendEvent) {
        this.sdk.server.send_event("Usuário passou pelo chat", "chat_hovered");
      }
    }
  }

  open(sendEvent: boolean = false) {
    if (this.options && this.box && this.launcher && this.currentStatus !== "opened" && this.currentStatus !== "engaged") {
      this.clear();
      this.classList.add("avatared");
      this.launcher.pause();
      this.style.height = `calc(100vh - ${this.options.maxTop + 94}px)`;
      this.box.open();
      if (this.sdk && this.sdk.server && sendEvent) {
        this.sdk.server.send_event("Usuário abriu o chat", "chat_opened");
      }
      this.currentStatus = "opened";
      const openedEvent = document.createEvent("CustomEvent");
      openedEvent.initCustomEvent("widget_opened", false, false, {});
      this.dispatchEvent(openedEvent);
    }
  }

  getUserAgent() {
    let browser = "não identificado";

    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf("OPR")) !== -1) {
      browser = "Opera";
    } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
      browser = "Chrome";
    } else if (navigator.userAgent.indexOf("Safari") !== -1) {
      browser = "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
      browser = "Firefox";
    } else if (navigator.userAgent.indexOf("MSIE") !== -1) {
      browser = "IE";
    } else {
      browser = "não identificado";
    }

    let isMobile = /Mobi/.test(navigator.userAgent);
    return {
      browser: browser,
      mobile: isMobile
    };
  }

  mobile() {
    if (this.getUserAgent().mobile) {
      this.classList.add("mobile");
    }
  }

  engaged(sendEvent: boolean = false) {
    if (this.options && this.box && this.launcher && this.currentStatus !== "engaged") {
      this.clear();
      this.classList.add("engaged");
      this.classList.add(this.options!.size);
      this.launcher.stop();
      this.style.height = `calc(100vh - ${this.options.maxTop + 32}px)`;
      this.box.engaged();
      if (sendEvent && this.sdk && this.sdk.server && this.sdk.user) {
        this.sdk.server.send_event("Usuário focou na caixa de texto", "chat_engaged");
      }
      this.currentStatus = "engaged";
      const engagedEvent = document.createEvent("CustomEvent");
      engagedEvent.initCustomEvent("widget_engaged", false, false, {});
      this.dispatchEvent(engagedEvent);
    }
  }

  render() {
    let extractedAttrOption = this.optionsHelper.extractOptionsFromAttributes(this.attributes);
    let extractedTagOption = this.optionsHelper.extractOptionsFromTag(this.innerHTML);
    this.options = merge(this.options, extractedAttrOption);
    this.options = merge(this.options, extractedTagOption);
  }

  connectedCallback() {
    if (this.options && !isUndefined(this.options.apiToken)) {
      this.setOptions(this.options);
    }
    // document.addEventListener('DOMContentLoaded', () => {
    // fix for safari
    window.setTimeout(() => {
      let extractedAttrOption = this.optionsHelper.extractOptionsFromAttributes(this.attributes);
      let extractedTagOption = this.optionsHelper.extractOptionsFromTag(this.innerHTML);
      this.options = merge(this.options, extractedAttrOption);
      this.options = merge(this.options, extractedTagOption);

      if (this.options) {
        this.options.apiToken = this.options.apiToken.replace(/(\r\n|\n)/g, "").trim();
        this.options.avatar = this.options.avatar.replace(/(\r\n|\n)/g, "").trim();
        this.options.serverUrl = this.options.serverUrl.replace(/(\r\n|\n)/g, "").trim();
      }

      this.setOptions(this.options);
      console.log(this.options);
      this.mobile();
    }, 10);
  }

  private startLauncher() {
    let hasInteract: boolean = get(this, "sdk.user.client.hasInteract", false);
    let showOnboarding = hasInteract ? false : true;
    if (!isUndefined(this.launcher)) {
      this.launcher.start(showOnboarding);
    }
  }
}
