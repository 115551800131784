import SdkOptions from "./options.sdk.model";

export default class WidgetOptions extends SdkOptions {
  // others
  public lang: any;
  public googleAnalyticsTrackingCode: string;
  public avatar: string;
  public size: string;
  public behavior: string;
  public maxTop: number;
  public title: string;
  public placeholder: string;
  public onboarding: any;
  public call: string;
  public tracking: boolean;
  public color?: string;
  public branded: boolean;
  public noClose: boolean;
  public whiteLabel: boolean;
  public disclaimer?: string;

  constructor(options: any) {
    super(options);
    this.maxTop =
      typeof options.maxTop === "string"
        ? parseInt(options.maxTop || "150", 10)
        : options.maxTop || 150;
    this.title = options.title || "Nama Bot";
    this.call = options.call || "Hello World";
    this.tracking = options.tracking;
    this.branded = options.branded;
    this.lang = options.lang;
    this.color = options.color;
    this.size = options.size || "default";
    this.behavior = options.behavior || "default";
    this.googleAnalyticsTrackingCode = options.googleAnalyticsTrackingCode || false;
    this.onboarding = options.onboarding || {};
    this.placeholder = options.placeholder || "Escreva sua mensagem...";
    this.avatar = options.avatar;
    this.noClose = options.noClose || false;
    this.whiteLabel = options.whiteLabel || false;
    this.disclaimer = options.disclaimer;
  }
}
