import {CustomElement} from '../custom-element';
import Message from '../../models/message.model';
import isUndefined from 'lodash/isUndefined';
import NamaBubble from '../atoms/bubble.atom';
import NamaAvatar from '../atoms/avatar.atom';
import NamaButton from '../atoms/button.atom';
import NamaCards from './cards.molecule';
import moment from 'moment';
import NamaEmoji from '../atoms/emoji.atom';

@CustomElement({
  selector: 'nama-message'
})
export default class NamaMessage extends HTMLElement{

  public bubble:NamaBubble;
  public avatar:NamaAvatar | undefined;
  public cards:NamaCards;
  public choices:Element;
  public template:Element;
  public type:string;

  constructor(public message:Message, public robotAvatar?:any, public onlyText?:boolean){
    super();
    this.createAttr();
    this.type = this.message.type
    this.bubble = new NamaBubble(this.message.text, this.message.created_at);
    if(!isUndefined(robotAvatar)){
      this.avatar = new NamaAvatar(this.robotAvatar.url, this.robotAvatar.name, 'small');
    }
    this.choices = document.createElement('nama-button-list');
    this.cards = new NamaCards(this.message.cards);
    this.template = document.createElement('template');
  }

  createAttr(){
    let fromAttr = document.createAttribute('from');
    fromAttr.value = this.message.from;
    this.attributes.setNamedItem(fromAttr);

    let typeAttr = document.createAttribute('type');
    typeAttr.value = this.message.type;
    this.attributes.setNamedItem(typeAttr);
  }

  renderButtons(){
    this.choices.classList.add("block");
    this.choices.classList.add("center");
    this.message.choices.forEach((choice:any) => {
      let href;
      let button_type;
      if (choice.type === 'web_url') {
        button_type = (choice.force_webview) ? '_webview' : '_blank';
        href = choice.url;
      } else {
        button_type = '_talk';
        href = choice.value;
      }
      let btn = new NamaButton(href, button_type, choice.webview_height_ratio);
      btn.innerHTML = new NamaEmoji(choice.value).parse();
      this.choices.appendChild(btn);
    })
    this.template.appendChild(this.choices);
  }

  renderChoices(){
    this.choices.classList.add("block");
    this.choices.classList.add("center");
    this.message.choices.forEach((choice:any) => {
      let btn = new NamaButton(choice.value, '_talk');
      btn.innerHTML = new NamaEmoji(choice.value).parse();
      this.choices.appendChild(btn);
    })
    this.template.appendChild(this.choices);
  }

  date() {
    let day = document.createElement('div');
    day.classList.add('date');
    day.classList.add(this.message.metadata.date_type);
    if(this.message.metadata.date_type === 'minute'){
      day.innerHTML = `<span>${moment(this.message.created_at).format('h')}h${moment(this.message.created_at).format('mm')}</span>`;
    } else{
      day.innerHTML = `<span>${moment(this.message.created_at).format('dddd, D of MMMM')}</span>`;
    }
    return day;
  }

  connectedCallback() {
    if(!this.message.sent_by_user && !isUndefined(this.avatar)){
      this.appendChild(this.avatar);
    }

    this.appendChild(this.template);

    if(this.message.type === 'date'){
      this.template.appendChild(this.date());
    } else {
      this.template.appendChild(this.bubble);
    }



    if(!this.onlyText){
      if(this.message.cards.length > 0){
        this.template.appendChild(this.cards);
      }

      if(this.message.metadata.show_choices_as === 'buttons' && this.message.choices.length > 0){
        this.renderButtons();
      }
    }
  }
}


