import {CustomElement} from "../custom-element";
import NamaMessage from '../molecules/message.molecule';
import NamaChoices from '../molecules/choices.molecule';
import NamaChatScroll from '../molecules/chat.scroll.molecule';
import Messages from '../../models/messages.model';
import Message from '../../models/message.model';

@CustomElement({
  selector: 'nama-chat'
})
export default class NamaChat extends HTMLElement{

    public list:HTMLElement;
    public chatScrollEl: NamaChatScroll;

    constructor(public messages:Messages,
                public orientation:string,
                public robotAvatar?:any,
                public sendMessengerAction?:any){
      super();
      let orientationAttr = document.createAttribute('orientation');
      orientationAttr.value = this.orientation;
      this.list = document.createElement("list");
      this.attributes.setNamedItem(orientationAttr);
      this.chatScrollEl = new NamaChatScroll();
    }

    onlyLast(){
      let messages = this.list.querySelectorAll("nama-message");
      if(messages.length > 0){

        for (let i = 0; i < messages.length; i++) {
           let item = messages[i] as HTMLElement;
           item.style.display = "none";
        }

        let last = messages.item(messages.length - 1) as HTMLElement;
        last.style.display = "table";

        this.style.height = null;
      }
    }

    hide(){
      this.classList.add("hide");
      this.classList.remove("show");
    }

    show(){
      this.classList.remove("hide");
      this.classList.add("show");
    }

    all(){
      let messages = this.list.querySelectorAll("nama-message");
      if(messages.length > 0){
        for (let i = 0; i < messages.length; i++) {
          let item = messages[i] as HTMLElement;
          item.style.display = "table";
        }
      }
    }

    render(){
      let choices = this.getElementsByTagName("nama-choices")[0];
      if(choices){
        this.removeChild(choices);
      }

      this.appendMessages();

      this.scroll();
    }

    scroll(){
      this.chatScrollEl.scrollTop = this.chatScrollEl.scrollHeight;
    }

    public bind(){
      this.messages.addEventListener('choices', (data:any) => {
        let choicesEl = new NamaChoices(data.detail, this.sendMessengerAction);
        this.appendChild(choicesEl);
        this.list.style.paddingBottom = choicesEl.offsetHeight + 'px';
        this.scroll();
      });

      this.messages.addEventListener('add', () => {
        this.list.style.paddingBottom = '0px';
        this.render();
      });
    }

    connectedCallback() {
      this.innerHTML = "";

      this.appendChild(this.chatScrollEl);

      this.chatScrollEl.appendChild(this.list);

      this.render();

      this.bind();
    }

    private appendMessages() {
      this.list.innerHTML = "";
      if(this.messages){
        this.messages.list.forEach((message:any, index:number) => {
          let messageEl = new NamaMessage(new Message(message), this.robotAvatar);
          if(index - 1 >= 0){
            let prev = this.list.children[index - 1] as NamaMessage;
            if(prev.type === messageEl.type){
              prev.classList.add('group');
              prev.classList.remove('last');
              messageEl.classList.add('group');
              messageEl.classList.add('last');
            } else {
              messageEl.classList.add('first');
            }
          } else{
            messageEl.classList.add('first');
          }

          this.list.appendChild(messageEl);
          index ++;
        });
      }
    }
}
