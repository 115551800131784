import PlatformApi from "./platform.api.strategy";
import SdkOptions from '../models/options.sdk.model';
import Messages from '../models/messages.model';
import Client from '../models/client.model';
import isUndefined from "lodash/isUndefined";
import last from "lodash/last";
import Message from "../models/message.model";

export default class PlatformPool {

  public isPoolingStarted: boolean = false;
  public updateTime: number = 1000;
  public lastMessageId: string = "-1";
  private chatTimeout: any;

  constructor(public apiToken:string,
              public client: Client,
              public messages: Messages,
              public api: PlatformApi,
              public options: SdkOptions) {
  }

  public start(force?:boolean) {
    this.resetUpdateFrequency();
    if (this.client.hasInteract || force) {
      this.request();
    }
  }

  public stop() {
    if (! isUndefined(this.chatTimeout)) {
      (window as any).clearTimeout(this.chatTimeout);
    }
  }

  public receive(data:any){
    if (data.length > 0) {
      let lastMessage: any = last(data);
      this.lastMessageId = lastMessage.id.toString();
      let messagesList:Array<Message> = [];
      data.forEach((message:Message) => {
        messagesList.push(new Message(message));
      });
      this.messages.add(messagesList);
    }
  }

  public handleSuccess(messages:any){
    if (messages.length > 0) {
      this.resetUpdateFrequency();
    } else {
      this.decreaseUpdateFrequency();
    }

    this.receive(messages);

    this.isPoolingStarted = true;
    this.client.hasInteract = true;

    this.stop();

    this.chatTimeout = setTimeout(() => {
      this.request();
    }, this.updateTime);
  }

  private request(): void {
    this.api.load(this.lastMessageId).then((request: any) => {
      this.handleSuccess(request.data);
    });
  }

  private resetUpdateFrequency() {
    this.updateTime = this.options.poolingTimeout;
  }

  private decreaseUpdateFrequency() {
    this.updateTime += this.options.poolingStep;
    if (this.updateTime > this.options.poolingLimit) {
      this.updateTime = this.options.poolingLimit;
    }
  }
}
