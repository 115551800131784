import {CustomElement} from "../custom-element";
import axios from 'axios';
import {Icons} from "./icon.manifest.atom";
import isUndefined from "lodash/isUndefined";
@CustomElement({
  selector: 'nama-icon'
})
export default class NamaIcon extends HTMLElement{
  public src:Attr | any;
  public icon:Attr | any;

  constructor(src?:string, icon?:string){
    super();
    if(typeof src !== 'undefined'){
      this.src = document.createAttribute('src');
      this.src.value = src;
    }
    if(typeof icon !== 'undefined'){
      this.icon = document.createAttribute('src');
      this.icon.value = icon;
    }
  }

  fillSrc(){
    let img = document.createElement('img');
    img.src = this.src.value;
    img.style.display = "none";

    this.appendChild(img);

    axios.get(this.src.value).then((svgContent:any) => {
      let svgTempDiv = document.createElement("div");
      svgTempDiv.innerHTML = svgContent.data;
      svgTempDiv.classList.add("svg-loaded");
      if(svgTempDiv.children.item(0) !== null){
        svgTempDiv.children.item(0).removeAttribute("width");
        svgTempDiv.children.item(0).removeAttribute("height");
        svgTempDiv.children.item(0).removeAttribute("fill");
        svgTempDiv.children.item(0).removeAttribute("xmlns:a");
      }
      if(img.parentElement){
        img.parentElement.replaceChild(svgTempDiv.children.item(0), img);
      }
    });
  }

  fillIcon(){
    let img = document.createElement('img');
    img.style.display = "none";
    this.appendChild(img);
    let svgTempDiv = document.createElement("div");
    const selectedIcon = this.icon.value;
    const loadedIcon = Icons[selectedIcon];
    if(!isUndefined(loadedIcon)){
      svgTempDiv.innerHTML =  loadedIcon + "";
      svgTempDiv.classList.add("svg-loaded");
      if(svgTempDiv.children.item(0) !== null){
        svgTempDiv.children.item(0).removeAttribute("width");
        svgTempDiv.children.item(0).removeAttribute("height");
        svgTempDiv.children.item(0).removeAttribute("fill");
        svgTempDiv.children.item(0).removeAttribute("xmlns:a");
      }
      if(img.parentElement){
        img.parentElement.replaceChild(svgTempDiv.children.item(0), img);
      }
    } else {
      console.log(selectedIcon + " not defined in nama-icon manifest")
    }
  }

  connectedCallback(){
    if(this.src == null){
      this.src = this.attributes.getNamedItem('src');
    }
    if(this.icon == null){
      this.icon = this.attributes.getNamedItem('icon');
    }
    if(this.children.length === 0){
      if(this.src !== null){
        this.fillSrc();
      } else if(this.icon !== null) {
        this.fillIcon();
      }
    }
  }
}
