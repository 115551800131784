import { CustomElement } from "../custom-element";
import isUndefined from "lodash/isUndefined";
import moment from "moment";
import NamaEmoji from "./emoji.atom";

@CustomElement({
  selector: "nama-bubble"
})
export default class NamaBubble extends HTMLElement {
  private links_regex = /(\b(?:https?|ftp|file):\/\/)([-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  private youtube_regex = /^.*(?:(?:youtu.be\/)|(?:v\/)|(?:\/u\/\w\/)|(?:embed\/)|(?:watch\?))\??v?=?([^#\&\? ]*)/gi;
  private temp_last_index: number;

  constructor(public text: string, public created_at?: string) {
    super();
    this.temp_last_index = 0;
  }

  connectedCallback() {
    this.parseTextWithSpecialLinks();
    this.parseEmojis();

    if (!isUndefined(this.created_at)) {
      let createAttr = document.createAttribute("created-at");
      createAttr.value = `${moment(this.created_at).format("h")}h${moment(this.created_at).format(
        "mm"
      )}`;
      this.attributes.setNamedItem(createAttr);
    }
  }

  private parseEmojis(): void {
    this.innerHTML = new NamaEmoji(this.innerHTML).parse();
  }

  private parseTextWithSpecialLinks(): void {
    let not_parsed_yet: string = this.text;
    let already_parsed: string;
    already_parsed = not_parsed_yet.replace(this.links_regex, (value) => `<a href="${value}" target="_blank">${value}</a>`)
    this.innerHTML = already_parsed.replace(/((\n|\r\n|\r)\s*)+/g, "<br/>");
  }

  private parseMap(special_link: string): string {
    let maps_embed_regex = /\/maps\/embed(\?[^#\&\? ]*)/gi;
    let maps_location_regex = /\@([0-9\.\,\-a-zA-Z]*)/gi;
    let embed_url;

    if (special_link.match(maps_embed_regex)) {
      embed_url = special_link;
    } else if (special_link.match(maps_location_regex)) {
      let matches = maps_location_regex.exec(special_link);
      if (matches && matches[1]) {
        let cordenates = matches[1].split(",");
        if (cordenates[0] && cordenates[1]) {
          embed_url =
            "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20000!2d" +
            cordenates[1] +
            "!3d" +
            cordenates[0] +
            "!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1486486434098";
        }
      }
    } else {
      return this.parseLink(special_link);
    }

    return `<iframe src="${embed_url}" class="nama-maps-iframe"></iframe>`;
  }

  private parseEmbedVideo(special_link: string): string {
    let match = this.youtube_regex.exec(special_link);
    if (match && !isUndefined(match[1]) && match[1].length === 11) {
      let embed_url = special_link.replace(this.youtube_regex, `https://www.youtube.com/embed/$1`);
      return `<iframe src="${embed_url}" frameborder="0" class="nama-youtube-iframe" allowfullscreen></iframe>`;
    }

    return this.parseLink(special_link);
  }

  private parseLink(special_link: string): string {
    return special_link.replace(this.links_regex, `<a href="$1$2" target="_blank">$2</a>`);
  }

  private parseSpecialLink(special_link: string): string {
    if (special_link.match("google.com/maps")) {
      return this.parseMap(special_link);
    } else if (special_link.match(this.youtube_regex)) {
      return this.parseEmbedVideo(special_link);
    } else {
      return this.parseLink(special_link);
    }
  }
}
