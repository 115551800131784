import * as ActionCable from 'actioncable';

/*
* The PlatformCableService Class
* Create a tunel to namas bot engine
* @author  Leonardo 'Alemax' Ferreira (leonardo.ferreira@nama.ai)
* @version 1.0
* @since   2017-12-18
*/
export default class PlatformCable{

  public isConnected: boolean = false;
  public cable: any;
  public channel: any;


  constructor(public apiToken:string,
              public websocketUrl:string,
              public userId:string) {
  }

  public create(connected:any, disconnected:any, received:any){
    this.cable = ActionCable.createConsumer(this.websocketUrl);

    this.channel = this.cable.subscriptions.create(
      {
        channel: 'MessagesChannel',
        room: this.apiToken + "_" + this.userId
      },
      {
        disconnected: () => {this.isConnected = false;  disconnected() },
        connected: () => { this.isConnected = true; connected() },
        received: (m:any) => {  received(m) }
      });
  }
}
