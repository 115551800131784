import {CustomElement} from "../custom-element";
import isUndefined from "lodash/isUndefined";
import NamaChat from "../organisms/chat.organism";

@CustomElement({
  selector: 'nama-webview'
})
export default class NamaWebview extends HTMLElement{
  private src:string;
  private closeEl:HTMLAnchorElement;
  private containerEl:HTMLDivElement;
  private close_Event: EventListener | undefined; // to use in removeEventListener

  constructor(src:string, size?:string){
    super();
    this.src = src;
    this.closeEl = document.createElement('a');
    this.containerEl = document.createElement('div');
    this.containerEl.classList.add('nama-webview-container');

    let height_class;
    switch (size) {
      case 'full':
        height_class = 'nama-full';
        break;
      case 'compact':
        height_class = 'nama-compact';
        break;
      default:
        height_class = 'nama-tall';
        break;
    }
    this.classList.add(height_class);
  }

  connectedCallback() {
    this.appendChild(this.containerEl);
    this.appendOverlay();
    this.appendHeader();
    this.appendIframe();
    this.bind();
    setTimeout(() => {
      this.open();
    }, 200);
  }

  public close() {
    this.classList.remove('nama-webview-opened');
    setTimeout(() => {
      this.remove();
    }, 300);
  }

  public open() {
    this.classList.add('nama-webview-opened');
  }

  private appendOverlay() {
    let overlayEl = document.createElement('div');
    overlayEl.classList.add('nama-webview-overlay');
    this.appendChild(overlayEl);
  }

  private appendHeader() {
    let headerEl = document.createElement('div');
    headerEl.classList.add('nama-webview-header');
    this.closeEl.href = '#';
    this.closeEl.innerHTML = this.closeSVG();
    this.closeEl.classList.add('nama-close-webview');
    headerEl.appendChild(this.closeEl);
    this.containerEl.appendChild(headerEl);
  }

  private bind() {
    this.close_Event = (event) => {
      event.preventDefault();
      this.close();
    }

    let namaChat:NamaChat | null = document.querySelector('nama-chat');
    if(namaChat){
      namaChat.messages.removeEventListener('add', this.close_Event);
      namaChat.messages.addEventListener('add', this.close_Event);
    }

    this.closeEl.removeEventListener('click', this.close_Event);
    this.closeEl.addEventListener('click', this.close_Event);
  }

  private appendIframe() {
    let iframeEl = document.createElement('iframe');
    iframeEl.src = this.src;
    this.containerEl.appendChild(iframeEl);
  }

  private closeSVG():string {
    return `
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <path d="M12.051,10.637 L14.707,13.293 C15.098,13.684 15.098,14.317 14.707,14.707 C14.512,14.903 14.256,15 14,15 C13.744,15 13.488,14.903 13.293,14.707 L10.668,12.082 C10.299,11.713 9.701,11.713 9.332,12.082 L6.707,14.707 C6.512,14.903 6.256,15 6,15 C5.744,15 5.489,14.903 5.293,14.707 C4.903,14.317 4.903,13.684 5.293,13.293 L7.887,10.699 C8.273,10.314 8.273,9.687 7.887,9.301 L5.293,6.707 C4.903,6.317 4.903,5.684 5.293,5.293 C5.684,4.903 6.317,4.903 6.707,5.293 L9.301,7.887 C9.687,8.273 10.313,8.273 10.699,7.887 L13.293,5.293 C13.684,4.903 14.317,4.903 14.707,5.293 C15.098,5.684 15.098,6.317 14.707,6.707 L12.051,9.364 C11.699,9.715 11.699,10.285 12.051,10.637 M10,0 C4.477,0 0,4.478 0,10 C0,15.523 4.477,20 10,20 C15.523,20 20,15.523 20,10 C20,4.478 15.523,0 10,0" id="Fill-1"></path>
    </svg>`
  }
}
