import isUndefined from "lodash/isUndefined";
import { AxiosPromise } from 'axios';
import PlatformApi from "../strategies/platform.api.strategy";
import PlatformCable from "../strategies/platform.cable.strategy";
import PlatformPool from "../strategies/platform.pool.strategy";
import SdkOptions from '../models/options.sdk.model';
import Messages from '../models/messages.model';
import Client from '../models/client.model';
import Message from "../models/message.model";
/*
* The PlatformApiService Class
* Connect to send and load messeges from nama api
* @author  Leonardo 'Alemax' Ferreira (leonardo.ferreira@nama.ai)
* @version 1.0
* @since   2018-03-09
*/
export default class Platform {

  public api: PlatformApi;
  public cable: PlatformCable;
  public pool: PlatformPool;
  public shouldStartCable: boolean;
  private received:any;

  constructor(public options: SdkOptions,
              public messages:Messages,
              public client:Client) {

    this.shouldStartCable = !(isUndefined(options.websocketUrl) || options.websocketUrl === "");
    this.api = new PlatformApi(this.options.apiToken, this.options.serverUrl, this.client.id);
    this.cable = new PlatformCable(this.options.apiToken, this.options.websocketUrl, this.client.id);
    this.pool = new PlatformPool(this.options.apiToken, this.client, this.messages, this.api, this.options);
  }

  public send(text: string,
    sensitive_information?: any,
    metadata?: any): AxiosPromise {

    let message = {
      id: (+new Date).toString(36),
      user: this.client.id,
      text: text,
      is_temp: true,
      sent_by_user: true,
      metadata: metadata,
      sensitive_information: sensitive_information
    };

    this.messages.add([new Message(message)]);

    return this.api.send(text, sensitive_information, metadata);
  }

  public send_event(text:string, event_name:string):void {
    if(this.client.hasInteract){
      this.api.send_event(text, event_name);
    }
  }

  public close_by_user(): void{
    this.api.close_by_user();

    if(this.pool.isPoolingStarted) {
      this.pool.stop();
    }
    if(this.cable.isConnected) {
      // TODO: Unsubscribe cable
    }
  }
}
