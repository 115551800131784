import {CustomElement} from "../custom-element";
import NamaChat from './chat.organism';
import NamaWidgetHeader from '../molecules/widget.header.molecule';
import NamaWidetInput from '../molecules/widget.input.molecule';
import WidgetOptions from '../../models/options.widget.model';
import Messages from '../../models/messages.model';

@CustomElement({
  selector: 'nama-chat-box'
})
export default class NamaChatBox extends HTMLElement{

  public options: WidgetOptions;
  public header: NamaWidgetHeader;
  public chat?: NamaChat;
  public input: NamaWidetInput;
  public send: Function | undefined;


  constructor(options:WidgetOptions, public messages?:Messages, public action?:any){
    super();
    this.options = new WidgetOptions(options || {});
    this.header = new NamaWidgetHeader(this.options.title, this.options.noClose);
    if(this.messages){
      this.chat = new NamaChat(this.messages, "user-right" , { url:this.options.avatar , name:this.options.title} , this.action);
      this.messages.addEventListener("operator:change", (event:any)=>{
        this.header.setOperator(event.detail.operator_name, event.detail.operator_avatar);
      })
    }
    this.input = new NamaWidetInput(this.options);
  }

  connectedCallback() {
    this.start();
  }

  close(){
    this.input.hide();
  }

  hover(){
    if(this.chat){
      this.input.hide();
      this.chat.onlyLast();
      this.chat.scroll();
    }
  }

  open(){
    if(this.chat){
      this.input.show();
      this.chat.all();
    }
  }

  engaged(){
    if(this.chat){
      this.input.show();
      this.chat.all();
      this.input.input.focus();
      this.chat.scroll();
    }
  }


  public start(){
    if(this.chat){
      this.innerHTML = "";
      this.appendChild(this.input);
      this.appendChild(this.chat);
      this.appendChild(this.header);
    }
  }
}


