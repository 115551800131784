import {CustomElement} from "../custom-element";
import WidgetOptions from '../../models/options.widget.model';
import Messages from '../../models/messages.model';
import NamaMessage from '../molecules/message.molecule';
import NamaNotificationBadge from "../atoms/notification-badge.atom";
import Message from "../../models/message.model";
import isUndefined from "lodash/isUndefined";

@CustomElement({
  selector: 'nama-widget-launcher'
})
export default class NamaWidgetLauncher extends HTMLElement{

  public showOnboarding: boolean = true;
  public coins: any = {};
  public animation: any = {};
  public actual!: string;
  public message!: NamaMessage | undefined;
  public notificationBadge: NamaNotificationBadge | undefined;
  private remainingFlipsToShowOnboarding: number = 0;

  constructor(public options:WidgetOptions, public messages?:Messages) {
    super();
    let chatIcon: string = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 841.9 1190.6" enable-background="new 0 0 841.9 1190.6" xml:space="preserve">
                                  <path fill="#F1F2F2" d="M508.4,631.7H264.6c-8.4,0-16.8,8.4-16.8,16.8c0,11.2,8.4,16.8,16.8,16.8h243.8c8.4,0,16.8-8.4,16.8-16.8C528,640.1,519.6,631.7,508.4,631.7z"/>
                                  <path fill="#F1F2F2" d="M508.4,738.2H264.6c-8.4,0-16.8,8.4-16.8,16.8c0,11.2,8.4,16.8,16.8,16.8h243.8c8.4,0,16.8-8.4,16.8-16.8C528,746.6,519.6,738.2,508.4,738.2z"/>
                                  <path fill="#F1F2F2" d="M508.4,844.7H264.6c-8.4,0-16.8,8.4-16.8,16.8c0,11.2,8.4,16.8,16.8,16.8h243.8c8.4,0,16.8-8.4,16.8-16.8C528,853.1,519.6,844.7,508.4,844.7z"/>
                                  <path fill="#F1F2F2" d="M825,203H298.2c-47.6,0-86.9,39.2-86.9,89.7v232.6H18c-8.4,0-14,5.6-16.8,11.2c-2.8,5.6,0,14,2.8,19.6l137.3,134.5v207.4c0,50.4,39.2,89.7,86.9,89.7H542c47.6,0,86.9-39.2,86.9-89.7V663.7c39.7-8,70.1-43.6,70.1-88V371.1l134.5-137.3c8.4-5.6,8.4-14,8.4-19.6C839,208.6,830.6,203,825,203z M334.7,312.3h243.8c8.4,0,16.8,5.6,16.8,16.8c0,8.4-8.4,16.8-16.8,16.8H334.7c-11.2,0-16.8-8.4-16.8-16.8S326.3,312.3,334.7,312.3z M334.7,415.9h243.8c11.2,0,19.6,8.4,16.8,16.8c0,8.4-8.4,16.8-16.8,16.8H334.7c-8.4,0-16.8-5.6-16.8-16.8C317.8,424.3,326.3,415.9,334.7,415.9z M544.8,951.1H228.2c-28,0-53.2-25.2-53.2-53.2v-213c0-5.6-2.8-8.4-5.6-14L60.1,558.8h482c28,0,53.2,25.2,53.2,53.2v285.8h2.8C598.1,928.7,575.6,951.1,544.8,951.1z"/>
                              </svg>`;

    this.coins['coin-chat'] = this.coin('coin-chat', chatIcon, "bot");
    this.coins['coin-conversable'] = this.coin('coin-conversable', `<nama-avatar name="${this.options.title}" url="${this.options.avatar}"></nama-avatar>`);
  }

  public coin(name: string, avatar: string, classes?: any) {
    let coin = document.createElement("div");
    coin.classList.add("coin");
    coin.classList.add(classes);
    coin.id = name;
    coin.classList.add("flip");
    coin.classList.add("out");
    coin.innerHTML = `<div class="avatar-container">${avatar}</div>`;
    return coin;
  }

  public start(showOnboarding: boolean) {
    this.showOnboarding = showOnboarding;
    clearTimeout(this.animation);
    this.animation = setTimeout(() => {
      this.classList.remove("hide");
      this.classList.add("show");
      this.flipTo("coin-chat", () => {
        if(this.showOnboarding) {
          this.popupOnboardingMessage();
        }
        this.flipTo("coin-conversable", () => {
          this.start(this.showOnboarding);
        }, 5000);
      }, 5000);
    }, 10);
    if(this.notificationBadge){
      this.notificationBadge.enable();
    }
  }

  public flipIn(coin: string) {
    if (this.coins[this.actual]) {
      this.coins[this.actual].classList.remove("in");
      this.coins[this.actual].classList.add("out");
    }
    if(this.coins[coin]){
      this.coins[coin].classList.remove("out");
      this.coins[coin].classList.add("in");
    }
    this.actual = coin;
  }

  public flipTo(coin: string, callback: any, wait: number) {
    this.flipIn(coin);
    this.animation = setTimeout(() => { callback(); }, wait + 600);
  }

  public pause() {
    clearTimeout(this.animation);
    if(this.message){
      this.message.classList.add("hidden");
    }
    if(this.notificationBadge){
      this.notificationBadge.disable();
    }
  }

  public stop() {
    clearTimeout(this.animation);
    this.classList.add("hide");
    this.classList.remove("show");
    if(this.message){
      this.message.classList.add("hidden");
    }
    if(this.notificationBadge){
      this.notificationBadge.disable();
    }
  }

  connectedCallback() {
    if(this.messages){
      let cels = document.createElement("div");
      cels.classList.add("coins");
      cels.appendChild(this.coins['coin-chat']);
      cels.appendChild(this.coins['coin-conversable']);
      this.appendChild(cels);
      this.notificationBadge = new NamaNotificationBadge(this.messages, false);
      this.notificationBadge.addEventListener('new_notification', (event:any) => {
        if(this.notificationBadge && this.notificationBadge.enabled){
          this.popupNewMessage(event.detail.last_unread_message);
        }
      });
      this.appendChild(this.notificationBadge);
    }
  }

  private popupNewMessage(message:Message){
    if(message){
      if(this.message){
        this.message.remove();
      }
      this.message = new NamaMessage(message, undefined, true);
      this.message.classList.add("hidden");
      setTimeout(() => {
        if(this.message) { this.message.classList.remove("hidden") }
      }, 100);
      this.appendChild(this.message);
      setTimeout(() => {
        if(this.message){
          this.message.classList.add("hidden");
        }
      }, 5500);
    }
  }

  private popupOnboardingMessage() {
    if(this.remainingFlipsToShowOnboarding === 0 && !isUndefined(this.options.onboarding) && this.options.onboarding.text !== ""){
      this.remainingFlipsToShowOnboarding = 4;
      this.popupNewMessage(this.options.onboarding);
    } else {
      this.remainingFlipsToShowOnboarding--;
    }
  }
}
