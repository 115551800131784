import isUndefined from "lodash/isUndefined";
import { CustomElement } from "../custom-element";
import NamaIcon from '../atoms/icon.atom';
import NamaButton from '../atoms/button.atom';
import NamaEmoji from "../atoms/emoji.atom";

@CustomElement({
  selector: 'nama-cards'
})
export default class NamaCards extends HTMLElement{

    public list: HTMLElement;
    public window: HTMLElement;
    public next: HTMLElement;
    public prev: HTMLElement;
    public selectedCard: number = 0;

    constructor(public cards?:Array<any>){
      super();
      this.next = document.createElement('nama-cards-arrow');
      this.prev = document.createElement('nama-cards-arrow');
      this.window = document.createElement('div');
      this.list = document.createElement('nama-cards-list') as HTMLElement;
    }

    calculateWidth(){
      if(!isUndefined(this.cards) && this.cards.length > 0){
        this.list.style.width = (this.cards.length * 208) + "px";
      }
    }

    setSelected(selected:string){
      let attr = this.attributes.getNamedItem('selected');
      if(attr === null){
        attr = document.createAttribute('selected');
        this.attributes.setNamedItem(attr);
      }
      attr.value = selected;
    }

    bind(){
      this.next.addEventListener('click', ()=>{
        this.nextCard();
      });
      this.prev.addEventListener('click', ()=>{
        this.prevCard();
      });
    }

    nextCard(){
      if(this.cards){
        if(this.selectedCard < this.cards.length - 1){
          this.select(this.selectedCard + 1);
        }
      }
    }

    prevCard(){
      if(this.cards){
        if(this.selectedCard - 1 >= 0){
          this.select(this.selectedCard - 1);
        }
      }
    }

    select(index:number){
      this.list.style.left = "-" + (200 * index) + "px";
      this.selectedCard = index;
      this.setSelected(index.toString());
      this.surrounding();
    }

    renderCards(){
      if(!isUndefined(this.cards) && this.cards.length > 0){
        let index = 0;
        this.cards.forEach((card:any) => {

          let cardEl = document.createElement("nama-card");

          let indexAttr = document.createAttribute('index');
          indexAttr.value = index.toString();
          cardEl.attributes.setNamedItem(indexAttr);

          let cardDisplayEl = document.createElement("nama-card-display");

          cardDisplayEl.style.backgroundImage = "url(" + card.image_url + ")";

          let cardDescription = document.createElement("description");
          let cardTitle = document.createElement("title");
          cardTitle.innerHTML = card.title;
          let subtitle = document.createElement("subtitle");
          subtitle.innerHTML = card.subtitle;

          if(!isUndefined(card.title)){
            cardDescription.appendChild(cardTitle);
          }
          if(!isUndefined(card.subtitle)){
            cardDescription.appendChild(subtitle);
          }
          if(!isUndefined(card.title) || !isUndefined(card.subtitle)){
          cardDisplayEl.appendChild(cardDescription);
          }

          cardEl.appendChild(cardDisplayEl);

          let choicesEl = this.renderChoices(card.choices);
          if(!isUndefined(choicesEl)){
            cardEl.appendChild(choicesEl);
          }

          this.list.appendChild(cardEl);

          index ++;
        });
      }
    }

    surrounding(){
      let actual = this.list.querySelector(`[index="${this.selectedCard}"]`);
      let prev = this.list.querySelector(`[index="${this.selectedCard - 1}"]`);
      let next = this.list.querySelector(`[index="${this.selectedCard + 1}"]`);

      let eventPrev = ()=>{
        this.prevCard();
        if(prev){
          prev.removeEventListener('click', eventPrev, false );
        }
      }
      let eventNext = ()=>{
        this.nextCard();
        if(next){
          next.removeEventListener('click', eventNext, false );
        }
      }

      if(prev){
        prev.addEventListener('click', eventPrev, false );
      }
      if(next){
        next.addEventListener('click', eventNext, false);
      }
    }

    connectedCallback() {
      this.window.classList.add("window");
      this.calculateWidth();

      this.renderCards();
      // isso aqui é feio, não facam
      const NAMA_FRONT_URL = (window as any).NamaEnv.NAMA_FRONT_URL;

      if(!isUndefined(this.cards) && this.cards.length > 1){
        this.prev.appendChild(new NamaIcon(NAMA_FRONT_URL + '/assets/images/icons/20/arrow-back.svg'))
        this.prev.classList.add("prev");
        this.appendChild(this.prev);
      }

      this.window.appendChild(this.list);
      this.appendChild(this.window);

      if(!isUndefined(this.cards) && this.cards.length > 1){
        this.next.appendChild(new NamaIcon(NAMA_FRONT_URL + '/assets/images/icons/20/arrow-foward.svg'))
        this.next.classList.add("next");
        this.appendChild(this.next);
        this.bind();
      }

      this.select(0);
    }

  private renderChoices(choices:any):HTMLElement | undefined {
    if(!isUndefined(choices) && choices.length > 0) {
      let choicesEl:HTMLElement = document.createElement('nama-button-list');
      choicesEl.classList.add("card");
      choicesEl.classList.add("center");
      choices.forEach((choice:any) => {
        let buttonEl;
        if(!isUndefined(choice.payload) && choice.payload !== '') {
          buttonEl = new NamaButton(choice.payload, '_talk_action');
        } else {
          buttonEl = new NamaButton(choice.value, '_talk');
        }
        buttonEl.innerHTML = new NamaEmoji(choice.value).parse();
        choicesEl.appendChild(buttonEl);
      });
      return choicesEl;
    }
  }
}


