import isUndefined from "lodash/isUndefined";
import camelCase from "lodash/camelCase";
import kebabCase from "lodash/kebabCase";
import WidgetOptions from "../models/options.widget.model";

export default class OptionsHelper {
  constructor() {
    //
  }

  public extractOptionsFromAttributes(attributes: any) {
    let options: any = {};
    for (let i = 0; i < attributes.length; i++) {
      let attrib = attributes[i];
      options[camelCase(attrib.name)] = attrib.value;
    }
    return options;
  }

  public retriveApiToken(apiToken: string, attributes: any) {
    if (isUndefined(apiToken)) {
      let attrApiToken = attributes.getNamedItem("api-token");
      if (attrApiToken) {
        apiToken = attrApiToken.value;
      }
    }
    return apiToken;
  }

  public retriveAuthToken(apiToken: string, attributes: any) {
    if (isUndefined(apiToken)) {
      let attrApiToken = attributes.getNamedItem("auth-token");
      if (attrApiToken) {
        apiToken = attrApiToken.value;
      }
    }
    return apiToken;
  }

  public retriveAttr(name: string, attributes: any) {
    if (!isUndefined(name)) {
      let atter = attributes.getNamedItem(name);
      if (atter) {
        return atter.value;
      }
    }
  }

  public extractChoicesFromTag(innerHTML: string): any {
    let el = document.createElement("html");
    el.innerHTML = `<choices>${innerHTML}</choices>`;
    let choices = el.querySelector("choices");
    if (choices) {
      let extractedChoices = [];
      let metadata: any = {};
      if (choices.children.length > 0) {
        for (let i = 0; i < choices.children.length; i++) {
          const choice = choices.children.item(i);
          if (choice) {
            if (camelCase(choice.tagName) === "choice") {
              if (typeof this.extractOptionsFromTag(choice.innerHTML) === "object") {
                extractedChoices.push(this.extractOptionsFromTag(choice.innerHTML));
              } else {
                extractedChoices.push({ value: this.extractOptionsFromTag(choice.innerHTML) });
              }
            } else {
              metadata[choice.tagName.toLowerCase()] = choice.innerHTML;
            }
          }
        }
      }
      return {
        choices: extractedChoices,
        metadata: metadata
      };
    }
  }

  public transformOptionsToTag(options: any) {
    let guideDefaut = new WidgetOptions({}) as any;

    let innerHTML = ``;
    if (!isUndefined(options)) {
      for (let key in options) {
        if (key === "onboarding") {
          innerHTML += `      <${kebabCase(key)}>\n`;
          innerHTML += `        <text>${options[key].text}</text>\n`;

          if (!isUndefined(options[key].choices)) {
            innerHTML += `        <choices>\n`;
            for (let choice of options[key].choices) {
              innerHTML += `         <choice>${choice.value}</choice>\n`;
            }
            innerHTML += `       </choices>\n`;
          }

          innerHTML += `     </${kebabCase(key)}>\n`;
        } else if (key === "singleUse") {
          innerHTML += `<single-use>${options[key]}</single-use>\n`;
        } else if (
          !isUndefined(options[key]) &&
          guideDefaut[key] !== options[key] &&
          key !== "style"
        ) {
          innerHTML += `     <${kebabCase(key)}>${options[key]}</${kebabCase(key)}>\n`;
        }
      }
    }
    return innerHTML;
  }

  public extractOptionsFromTag(innerHTML: string) {
    let el = document.createElement("html");
    el.innerHTML = `<options>${innerHTML}</options>`;
    let options = el.querySelector("options");
    if (options) {
      if (options.children.length > 0) {
        let extractedOptions: any = {};
        for (let i = 0; i < options.children.length; i++) {
          const child = options.children.item(i);
          if (child) {
            if (camelCase(child.tagName) === "choices") {
              let choices = this.extractChoicesFromTag(child.innerHTML);
              extractedOptions[camelCase(child.tagName)] = choices.choices;
              extractedOptions["metadata"] = choices.metadata;
            } else if (camelCase(child.tagName) === "include") {
              extractedOptions["include"] = child.innerHTML
            } else {

              extractedOptions[camelCase(child.tagName)] = this.extractOptionsFromTag(
                child.innerHTML
              );
            }
          }
        }
        return extractedOptions;
      } else {
        return innerHTML;
      }
    }
  }

  // public extract(innerHTML:string, findInOptions?:boolean){
  //   let newOptions:any = {};
  //   let el = document.createElement( 'html' );

  //   if(findInOptions){

  //   } else{
  //     el.innerHTML = innerHTML;
  //   }
  //   let options =el.querySelector("options");
  //   if(options){
  //     for (let i = 0; i < options.children.length; i++) {
  //       const child = options.children.item(i);
  //       if(child.tagName === 'ONBOARDING'){
  //         newOptions[camelCase(child.tagName)] =
  //         this.extract(child.innerHTML, true);
  //       } else {
  //         newOptions[camelCase(child.tagName)] = child.innerHTML;
  //       }
  //     }
  //   }
  //   return newOptions;
  // }
}
