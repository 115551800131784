import {CustomElement} from "../custom-element";
import Messages from "../../models/messages.model";
import isUndefined from "lodash/isUndefined";
import filter from 'lodash/filter';
import Message from "../../models/message.model";

@CustomElement({
  selector: 'nama-notification-badge'
})
export default class NamaNotificationBadge extends HTMLElement{
  public enabled:boolean = false;
  private onlyListenUserMessages:boolean;
  private audio = new Audio("https://cdn.nama.ai/notification.wav");
  /**
   * @param onlyListenUserMessages true if it's Desk
   */
  constructor(public messages:Messages, onlyListenUserMessages:boolean){
    super();
    this.onlyListenUserMessages = onlyListenUserMessages;
  }

  connectedCallback() {
    if(!isUndefined(this.messages)) {
      this.messages.addEventListener('add', () => {
        this.checkMessages();
      });
    }
  }

  public unreadMessages():Message[] | undefined {
    if(this.messages) {
      if(this.onlyListenUserMessages) {
        return filter(this.messages.list, (m:Message) => m.sent_by_user && m.type !== "system" && !m.visualized);
      } else {
        return filter(this.messages.list, (m:Message) => !(m.sent_by_user || m.type === 'onboarding' || m.visualized));
      }
    }
  }

  public enable(){
    this.enabled = true;
  }

  public disable(){
    this.enabled = false;
    this.markAllAsRead();
  }

  private markAllAsRead(){
    if(this.messages){
      this.messages.list.forEach((message:Message) => {
        message.visualized = true;
        message.notified = true;
      });
      this.messages.updateStorage();
    }
    this.classList.remove('nama-show');
  }

  private markAllAsNotified(){
    if(this.messages){
      this.messages.list.forEach((message:Message) => {
        message.notified = true;
      });
      this.messages.updateStorage();
    }
  }

  private notify(unread_messages: Message[]) {
    let amount_unread_messages = unread_messages.length;

    if(this.enabled && this.innerHTML !== amount_unread_messages.toString()) { // Prevent double notifications
      this.classList.add('nama-show');
      this.innerHTML = `${amount_unread_messages}`;

      // Do not notify the same message twice. Good when browsing and chating
      if(filter(unread_messages, (m:Message) => !m.notified).length > 0){
        this.markAllAsNotified();
        this.audio.play();

        const notifyEvent = document.createEvent("CustomEvent");
        notifyEvent.initCustomEvent("new_notification", false, false, {
          amount_unread_messages: amount_unread_messages,
          last_message: this.messages.list[this.messages.list.length - 1],
          last_unread_message: unread_messages[amount_unread_messages - 1]
        });
        this.dispatchEvent(notifyEvent);
      }
    }
  }

  private checkMessages() {
    if(!this.enabled){
      this.markAllAsRead();
    }
    let unread_messages = this.unreadMessages();
    if(!isUndefined(unread_messages) && unread_messages.length > 0) {
      this.notify(unread_messages);
    }
  }
}


