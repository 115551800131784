import {CustomElement} from "../custom-element";
@CustomElement({
  selector: 'nama-chat-scroll'
})
export default class NamaChatScroll extends HTMLElement{
  constructor(){
    super();
  }

  connectedCallback() {
    //
  }
}


