export const Icons:any = {
 add : require('../../assets/images/icons/20/add.svg'),
 bak : require('../../assets/images/icons/20/bak.svg'),
 debug : require('../../assets/images/icons/20/debug.svg'),
 dropdown : require('../../assets/images/icons/20/dropdown.svg'),
 folder : require('../../assets/images/icons/20/folder.svg'),
 launch : require('../../assets/images/icons/20/launch.svg'),
 options : require('../../assets/images/icons/20/options.svg'),
 publish : require('../../assets/images/icons/20/publish.svg'),
 teams : require('../../assets/images/icons/20/teams.svg'),
 analytics : require('../../assets/images/icons/20/analytics.svg'),
 bot : require('../../assets/images/icons/20/bot.svg'),
 delete : require('../../assets/images/icons/20/delete.svg'),
 edit : require('../../assets/images/icons/20/edit.svg'),
 forward : require('../../assets/images/icons/20/forward.svg'),
 line : require('../../assets/images/icons/20/line.svg'),
 password : require('../../assets/images/icons/20/password.svg'),
 send : require('../../assets/images/icons/20/send.svg'),
 transfer : require('../../assets/images/icons/20/transfer.svg'),
 'arrow-back' : require('../../assets/images/icons/20/arrow-back.svg'),
 change : require('../../assets/images/icons/20/change.svg'),
 desk : require('../../assets/images/icons/20/desk.svg'),
 email : require('../../assets/images/icons/20/email.svg'),
 foward : require('../../assets/images/icons/20/foward.svg'),
 location : require('../../assets/images/icons/20/location.svg'),
 payment : require('../../assets/images/icons/20/payment.svg'),
 settings : require('../../assets/images/icons/20/settings.svg'),
 trash : require('../../assets/images/icons/20/trash.svg'),
 'arrow-foward' : require('../../assets/images/icons/20/arrow-foward.svg'),
 channels : require('../../assets/images/icons/20/channels.svg'),
 desktop : require('../../assets/images/icons/20/desktop.svg'),
 emoji : require('../../assets/images/icons/20/emoji.svg'),
 home : require('../../assets/images/icons/20/home.svg'),
 minimize : require('../../assets/images/icons/20/minimize.svg'),
 phone : require('../../assets/images/icons/20/phone.svg'),
 smartphone : require('../../assets/images/icons/20/smartphone.svg'),
 visible : require('../../assets/images/icons/20/visible.svg'),
 attachement : require('../../assets/images/icons/20/attachement.svg'),
 chat : require('../../assets/images/icons/20/chat.svg'),
 disconect : require('../../assets/images/icons/20/disconect.svg'),
 error : require('../../assets/images/icons/20/error.svg'),
 next : require('../../assets/images/icons/20/next.svg'),
 plus : require('../../assets/images/icons/20/plus.svg'),
 suggestions : require('../../assets/images/icons/20/suggestions.svg'),
 back : require('../../assets/images/icons/20/back.svg'),
 copy : require('../../assets/images/icons/20/copy.svg'),
 'dropdown-single' : require('../../assets/images/icons/20/dropdown-single.svg'),
 filter : require('../../assets/images/icons/20/filter.svg'),
 information : require('../../assets/images/icons/20/information.svg'),
 ok : require('../../assets/images/icons/20/ok.svg'),
 profile : require('../../assets/images/icons/20/profile.svg'),
 tablet : require('../../assets/images/icons/20/tablet.svg')
}
