import isUndefined from "lodash/isUndefined";
import {CustomElement} from "../custom-element";

@CustomElement({
  selector: 'nama-avatar'
})
export default class NamaAvatar extends HTMLElement{

  public size:string = "40px";
  public status:HTMLElement;

    constructor(public url?:string , public name?:string, size?:string){
      super();
      if(!isUndefined(url)){
        let urlAttr = document.createAttribute('url');
        urlAttr.value = url;
        this.attributes.setNamedItem(urlAttr);
      }

      if(!isUndefined(name)){
        let nameAttr = document.createAttribute('name');
        nameAttr.value = name;
        this.attributes.setNamedItem(nameAttr);
      }

      if(!isUndefined(size)){
        if(size === 'big'){
          this.size = "68px";
        } else if(size === 'small'){
          this.size = "32px";
        }
      }

      this.status = document.createElement("nama-status");

    }

    changeStatus(status:string){
      this.status.className = "";
      this.status.classList.add(status);

      let locate = this.querySelector("nama-status");
      if(!locate){
        this.appendChild(this.status);
      }
    }

    reload(){
      this.innerHTML = "";
      let attrUrl = this.attributes.getNamedItem('url');
      if(attrUrl){
        this.url = attrUrl.value;
      }
      let attrName = this.attributes.getNamedItem('name');
      if(attrName){
        this.name = attrName.value;
      }
      let attrSize = this.attributes.getNamedItem('size');
      if(attrSize){
        let size = attrSize.value;
        if(size === 'big'){
          this.size = "68px";
        } else if(size === 'small'){
          this.size = "32px";
        }
      }
      if(this.hasImage()){
        this.style.backgroundImage = "url(" + this.url + ")";
        this.style.backgroundColor = "transparent";
      } else if (!isUndefined(this.name)){
        this.fill();
        this.style.backgroundColor = "transparent";
      }
      this.style.width = this.size;
      this.style.height = this.size;
    }

    connectedCallback() {
      this.reload();
    }

    fill() {
      this.style.borderRadius = (this) ? this.size : '0px';
      const colours = ['#00BFA5', '#F50057', '#FF7043', '#FFB300', '#8D25AA', '#F06292', '#49148B',
        '#41A2F0', '#2962FF', '#183B99','#00BFA5', '#F50057', '#FF7043', '#FFB300', '#8D25AA', '#F06292', '#49148B',
        '#41A2F0', '#2962FF', '#183B99'];
      const initials = this.getInitials(this.name);

      const charIndex = initials.charCodeAt(0) - 65;
      const colourIndex = charIndex % 20;

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const canvasWidth = this.size;
      const canvasHeight = this.size;
      const canvasCssWidth = parseInt(this.size || canvasWidth, 10) || 40;
      const canvasCssHeight = parseInt(this.size || canvasHeight, 10) || 40;
      canvas.width = canvasCssWidth;
      canvas.height = canvasCssHeight;
      if(context){
        context.fillStyle = '#D2DADE';
        context.fillRect(0, 0, canvasCssWidth, canvasCssHeight);
        context.font = canvasCssWidth / 2 + 'px \'Arial\'';
        context.textAlign = 'center';
        context.fillStyle = '#6A6B6B';
        context.fillText(initials, canvasCssWidth / 1.95, canvasCssHeight / 1.45);
      }

      if(this.children.length === 0){
        let locate = this.querySelector("nama-status");
        if(!locate){
          this.appendChild(canvas);
        } else {
          this.innerHTML = "";
          this.appendChild(canvas);
          this.appendChild(this.status);
        }
      }
    }

    getInitials(name:any) {
      const nameSplit = name.split(' ');
      if (nameSplit.length > 1) {
        return nameSplit[0].charAt(0).toUpperCase() + nameSplit[1].charAt(0).toUpperCase();
      } else {
        return nameSplit[0].charAt(0).toUpperCase() + nameSplit[0].charAt(1).toUpperCase();
      }
    }

    private hasImage(): boolean {
      return typeof this.url !== 'undefined' ? (this.url !== null && this.url.trim() !== '') : false;
    }
}


