import {CustomElement} from "../custom-element";

@CustomElement({
  selector: 'nama-emoji'
})
export default class NamaEmoji{
  constructor(public text:string){
  }

  // Replace shortnames emojis with twemoji emojis
  public parse():string {
    let pattern_all_emojis = this.pattern_all_emojis();
    let all_emojis = this.all_emojis();
    this.text = this.text.replace(pattern_all_emojis, (match) => {
      let emoji_code = all_emojis[match];
      const NAMA_FRONT_URL = (window as any).NamaEnv.NAMA_FRONT_URL;
      return `<img src="${NAMA_FRONT_URL}/assets/images/emojis/${emoji_code}.svg" class="nama-emoji">`;
    });
    return this.text;
  }

  private pattern_all_emojis():RegExp{
    let pattern = "";
    let all_emojis_keys = Object.keys(this.all_emojis());
    all_emojis_keys.forEach((emoji, index) => {
      pattern += emoji;
      if((index + 1) < all_emojis_keys.length){
        pattern += '|';
      }
    });
    return new RegExp(`(${pattern})`, 'g');
  }

  // https://github.com/jollygoodcode/twemoji/blob/master/lib/twemoji/data/emoji-unicode-svg.yml
  private all_emojis():{ [key: string]: string } {
    return {
      ":mahjong:": "1f004",
      ":black_joker:": "1f0cf",
      ":a_negative:": "1f170",
      ":b_negative:": "1f171",
      ":o_negative:": "1f17e",
      ":parking:": "1f17f",
      ":ab:": "1f18e",
      ":cl:": "1f191",
      ":cool:": "1f192",
      ":free:": "1f193",
      ":id:": "1f194",
      ":new:": "1f195",
      ":ng:": "1f196",
      ":ok:": "1f197",
      ":sos:": "1f198",
      ":up:": "1f199",
      ":vs:": "1f19a",
      ":a:": "1f1e6",
      ":flag-ac:": "1f1e6-1f1e8",
      ":flag-ad:": "1f1e6-1f1e9",
      ":flag-ae:": "1f1e6-1f1ea",
      ":flag-af:": "1f1e6-1f1eb",
      ":flag-ag:": "1f1e6-1f1ec",
      ":flag-ai:": "1f1e6-1f1ee",
      ":flag-al:": "1f1e6-1f1f1",
      ":flag-am:": "1f1e6-1f1f2",
      ":flag-ao:": "1f1e6-1f1f4",
      ":flag-aq:": "1f1e6-1f1f6",
      ":flag-ar:": "1f1e6-1f1f7",
      ":flag-as:": "1f1e6-1f1f8",
      ":flag-at:": "1f1e6-1f1f9",
      ":flag-au:": "1f1e6-1f1fa",
      ":flag-aw:": "1f1e6-1f1fc",
      ":flag-ax:": "1f1e6-1f1fd",
      ":flag-az:": "1f1e6-1f1ff",
      ":b:": "1f1e7",
      ":flag-ba:": "1f1e7-1f1e6",
      ":flag-bb:": "1f1e7-1f1e7",
      ":flag-bd:": "1f1e7-1f1e9",
      ":flag-be:": "1f1e7-1f1ea",
      ":flag-bf:": "1f1e7-1f1eb",
      ":flag-bg:": "1f1e7-1f1ec",
      ":flag-bh:": "1f1e7-1f1ed",
      ":flag-bi:": "1f1e7-1f1ee",
      ":flag-bj:": "1f1e7-1f1ef",
      ":flag-bl:": "1f1e7-1f1f1",
      ":flag-bm:": "1f1e7-1f1f2",
      ":flag-bn:": "1f1e7-1f1f3",
      ":flag-bo:": "1f1e7-1f1f4",
      ":flag-bq:": "1f1e7-1f1f6",
      ":flag-br:": "1f1e7-1f1f7",
      ":flag-bs:": "1f1e7-1f1f8",
      ":flag-bt:": "1f1e7-1f1f9",
      ":flag-bv:": "1f1e7-1f1fb",
      ":flag-bw:": "1f1e7-1f1fc",
      ":flag-by:": "1f1e7-1f1fe",
      ":flag-bz:": "1f1e7-1f1ff",
      ":c:": "1f1e8",
      ":flag-ca:": "1f1e8-1f1e6",
      ":flag-cc:": "1f1e8-1f1e8",
      ":flag-cd:": "1f1e8-1f1e9",
      ":flag-cf:": "1f1e8-1f1eb",
      ":flag-cg:": "1f1e8-1f1ec",
      ":flag-ch:": "1f1e8-1f1ed",
      ":flag-ci:": "1f1e8-1f1ee",
      ":flag-ck:": "1f1e8-1f1f0",
      ":flag-cl:": "1f1e8-1f1f1",
      ":flag-cm:": "1f1e8-1f1f2",
      ":flag-cn:": "1f1e8-1f1f3",
      ":flag-co:": "1f1e8-1f1f4",
      ":flag-cp:": "1f1e8-1f1f5",
      ":flag-cr:": "1f1e8-1f1f7",
      ":flag-cu:": "1f1e8-1f1fa",
      ":flag-cv:": "1f1e8-1f1fb",
      ":flag-cw:": "1f1e8-1f1fc",
      ":flag-cx:": "1f1e8-1f1fd",
      ":flag-cy:": "1f1e8-1f1fe",
      ":flag-cz:": "1f1e8-1f1ff",
      ":d:": "1f1e9",
      ":flag-de:": "1f1e9-1f1ea",
      ":flag-dg:": "1f1e9-1f1ec",
      ":flag-dj:": "1f1e9-1f1ef",
      ":flag-dk:": "1f1e9-1f1f0",
      ":flag-dm:": "1f1e9-1f1f2",
      ":flag-do:": "1f1e9-1f1f4",
      ":flag-dz:": "1f1e9-1f1ff",
      ":e:": "1f1ea",
      ":flag-ea:": "1f1ea-1f1e6",
      ":flag-ec:": "1f1ea-1f1e8",
      ":flag-ee:": "1f1ea-1f1ea",
      ":flag-eg:": "1f1ea-1f1ec",
      ":flag-eh:": "1f1ea-1f1ed",
      ":flag-er:": "1f1ea-1f1f7",
      ":flag-es:": "1f1ea-1f1f8",
      ":flag-et:": "1f1ea-1f1f9",
      ":flag-eu:": "1f1ea-1f1fa",
      ":f:": "1f1eb",
      ":flag-fi:": "1f1eb-1f1ee",
      ":flag-fj:": "1f1eb-1f1ef",
      ":flag-fk:": "1f1eb-1f1f0",
      ":flag-fm:": "1f1eb-1f1f2",
      ":flag-fo:": "1f1eb-1f1f4",
      ":flag-fr:": "1f1eb-1f1f7",
      ":g:": "1f1ec",
      ":flag-ga:": "1f1ec-1f1e6",
      ":flag-gb:": "1f1ec-1f1e7",
      ":flag-gd:": "1f1ec-1f1e9",
      ":flag-ge:": "1f1ec-1f1ea",
      ":flag-gf:": "1f1ec-1f1eb",
      ":flag-gg:": "1f1ec-1f1ec",
      ":flag-gh:": "1f1ec-1f1ed",
      ":flag-gi:": "1f1ec-1f1ee",
      ":flag-gl:": "1f1ec-1f1f1",
      ":flag-gm:": "1f1ec-1f1f2",
      ":flag-gn:": "1f1ec-1f1f3",
      ":flag-gp:": "1f1ec-1f1f5",
      ":flag-gq:": "1f1ec-1f1f6",
      ":flag-gr:": "1f1ec-1f1f7",
      ":flag-gs:": "1f1ec-1f1f8",
      ":flag-gt:": "1f1ec-1f1f9",
      ":flag-gu:": "1f1ec-1f1fa",
      ":flag-gw:": "1f1ec-1f1fc",
      ":flag-gy:": "1f1ec-1f1fe",
      ":h:": "1f1ed",
      ":flag-hk:": "1f1ed-1f1f0",
      ":flag-hm:": "1f1ed-1f1f2",
      ":flag-hn:": "1f1ed-1f1f3",
      ":flag-hr:": "1f1ed-1f1f7",
      ":flag-ht:": "1f1ed-1f1f9",
      ":flag-hu:": "1f1ed-1f1fa",
      ":i:": "1f1ee",
      ":flag-ic:": "1f1ee-1f1e8",
      ":flag-id:": "1f1ee-1f1e9",
      ":flag-ie:": "1f1ee-1f1ea",
      ":flag-il:": "1f1ee-1f1f1",
      ":flag-im:": "1f1ee-1f1f2",
      ":flag-in:": "1f1ee-1f1f3",
      ":flag-io:": "1f1ee-1f1f4",
      ":flag-iq:": "1f1ee-1f1f6",
      ":flag-ir:": "1f1ee-1f1f7",
      ":flag-is:": "1f1ee-1f1f8",
      ":flag-it:": "1f1ee-1f1f9",
      ":j:": "1f1ef",
      ":flag-je:": "1f1ef-1f1ea",
      ":flag-jm:": "1f1ef-1f1f2",
      ":flag-jo:": "1f1ef-1f1f4",
      ":flag-jp:": "1f1ef-1f1f5",
      ":k:": "1f1f0",
      ":flag-ke:": "1f1f0-1f1ea",
      ":flag-kg:": "1f1f0-1f1ec",
      ":flag-kh:": "1f1f0-1f1ed",
      ":flag-ki:": "1f1f0-1f1ee",
      ":flag-km:": "1f1f0-1f1f2",
      ":flag-kn:": "1f1f0-1f1f3",
      ":flag-kp:": "1f1f0-1f1f5",
      ":flag-kr:": "1f1f0-1f1f7",
      ":flag-kw:": "1f1f0-1f1fc",
      ":flag-ky:": "1f1f0-1f1fe",
      ":flag-kz:": "1f1f0-1f1ff",
      ":l:": "1f1f1",
      ":flag-la:": "1f1f1-1f1e6",
      ":flag-lb:": "1f1f1-1f1e7",
      ":flag-lc:": "1f1f1-1f1e8",
      ":flag-li:": "1f1f1-1f1ee",
      ":flag-lk:": "1f1f1-1f1f0",
      ":flag-lr:": "1f1f1-1f1f7",
      ":flag-ls:": "1f1f1-1f1f8",
      ":flag-lt:": "1f1f1-1f1f9",
      ":flag-lu:": "1f1f1-1f1fa",
      ":flag-lv:": "1f1f1-1f1fb",
      ":flag-ly:": "1f1f1-1f1fe",
      ":m:": "1f1f2",
      ":flag-ma:": "1f1f2-1f1e6",
      ":flag-mc:": "1f1f2-1f1e8",
      ":flag-md:": "1f1f2-1f1e9",
      ":flag-me:": "1f1f2-1f1ea",
      ":flag-mf:": "1f1f2-1f1eb",
      ":flag-mg:": "1f1f2-1f1ec",
      ":flag-mh:": "1f1f2-1f1ed",
      ":flag-mk:": "1f1f2-1f1f0",
      ":flag-ml:": "1f1f2-1f1f1",
      ":flag-mm:": "1f1f2-1f1f2",
      ":flag-mn:": "1f1f2-1f1f3",
      ":flag-mo:": "1f1f2-1f1f4",
      ":flag-mp:": "1f1f2-1f1f5",
      ":flag-mq:": "1f1f2-1f1f6",
      ":flag-mr:": "1f1f2-1f1f7",
      ":flag-ms:": "1f1f2-1f1f8",
      ":flag-mt:": "1f1f2-1f1f9",
      ":flag-mu:": "1f1f2-1f1fa",
      ":flag-mv:": "1f1f2-1f1fb",
      ":flag-mw:": "1f1f2-1f1fc",
      ":flag-mx:": "1f1f2-1f1fd",
      ":flag-my:": "1f1f2-1f1fe",
      ":flag-mz:": "1f1f2-1f1ff",
      ":n:": "1f1f3",
      ":flag-na:": "1f1f3-1f1e6",
      ":flag-nc:": "1f1f3-1f1e8",
      ":flag-ne:": "1f1f3-1f1ea",
      ":flag-nf:": "1f1f3-1f1eb",
      ":flag-ng:": "1f1f3-1f1ec",
      ":flag-ni:": "1f1f3-1f1ee",
      ":flag-nl:": "1f1f3-1f1f1",
      ":flag-no:": "1f1f3-1f1f4",
      ":flag-np:": "1f1f3-1f1f5",
      ":flag-nr:": "1f1f3-1f1f7",
      ":flag-nu:": "1f1f3-1f1fa",
      ":flag-nz:": "1f1f3-1f1ff",
      ":o:": "1f1f4",
      ":flag-om:": "1f1f4-1f1f2",
      ":p:": "1f1f5",
      ":flag-pa:": "1f1f5-1f1e6",
      ":flag-pe:": "1f1f5-1f1ea",
      ":flag-pf:": "1f1f5-1f1eb",
      ":flag-pg:": "1f1f5-1f1ec",
      ":flag-ph:": "1f1f5-1f1ed",
      ":flag-pk:": "1f1f5-1f1f0",
      ":flag-pl:": "1f1f5-1f1f1",
      ":flag-pm:": "1f1f5-1f1f2",
      ":flag-pn:": "1f1f5-1f1f3",
      ":flag-pr:": "1f1f5-1f1f7",
      ":flag-ps:": "1f1f5-1f1f8",
      ":flag-pt:": "1f1f5-1f1f9",
      ":flag-pw:": "1f1f5-1f1fc",
      ":flag-py:": "1f1f5-1f1fe",
      ":q:": "1f1f6",
      ":flag-qa:": "1f1f6-1f1e6",
      ":r:": "1f1f7",
      ":flag-re:": "1f1f7-1f1ea",
      ":flag-ro:": "1f1f7-1f1f4",
      ":flag-rs:": "1f1f7-1f1f8",
      ":flag-ru:": "1f1f7-1f1fa",
      ":flag-rw:": "1f1f7-1f1fc",
      ":s:": "1f1f8",
      ":flag-sa:": "1f1f8-1f1e6",
      ":flag-sb:": "1f1f8-1f1e7",
      ":flag-sc:": "1f1f8-1f1e8",
      ":flag-sd:": "1f1f8-1f1e9",
      ":flag-se:": "1f1f8-1f1ea",
      ":flag-sg:": "1f1f8-1f1ec",
      ":flag-sh:": "1f1f8-1f1ed",
      ":flag-si:": "1f1f8-1f1ee",
      ":flag-sj:": "1f1f8-1f1ef",
      ":flag-sk:": "1f1f8-1f1f0",
      ":flag-sl:": "1f1f8-1f1f1",
      ":flag-sm:": "1f1f8-1f1f2",
      ":flag-sn:": "1f1f8-1f1f3",
      ":flag-so:": "1f1f8-1f1f4",
      ":flag-sr:": "1f1f8-1f1f7",
      ":flag-ss:": "1f1f8-1f1f8",
      ":flag-st:": "1f1f8-1f1f9",
      ":flag-sv:": "1f1f8-1f1fb",
      ":flag-sx:": "1f1f8-1f1fd",
      ":flag-sy:": "1f1f8-1f1fe",
      ":flag-sz:": "1f1f8-1f1ff",
      ":t:": "1f1f9",
      ":flag-ta:": "1f1f9-1f1e6",
      ":flag-tc:": "1f1f9-1f1e8",
      ":flag-td:": "1f1f9-1f1e9",
      ":flag-tf:": "1f1f9-1f1eb",
      ":flag-tg:": "1f1f9-1f1ec",
      ":flag-th:": "1f1f9-1f1ed",
      ":flag-tj:": "1f1f9-1f1ef",
      ":flag-tk:": "1f1f9-1f1f0",
      ":flag-tl:": "1f1f9-1f1f1",
      ":flag-tm:": "1f1f9-1f1f2",
      ":flag-tn:": "1f1f9-1f1f3",
      ":flag-to:": "1f1f9-1f1f4",
      ":flag-tr:": "1f1f9-1f1f7",
      ":flag-tt:": "1f1f9-1f1f9",
      ":flag-tv:": "1f1f9-1f1fb",
      ":flag-tw:": "1f1f9-1f1fc",
      ":flag-tz:": "1f1f9-1f1ff",
      ":u:": "1f1fa",
      ":flag-ua:": "1f1fa-1f1e6",
      ":flag-ug:": "1f1fa-1f1ec",
      ":flag-um:": "1f1fa-1f1f2",
      ":flag-us:": "1f1fa-1f1f8",
      ":flag-uy:": "1f1fa-1f1fe",
      ":flag-uz:": "1f1fa-1f1ff",
      ":v:": "1f1fb",
      ":flag-va:": "1f1fb-1f1e6",
      ":flag-vc:": "1f1fb-1f1e8",
      ":flag-ve:": "1f1fb-1f1ea",
      ":flag-vg:": "1f1fb-1f1ec",
      ":flag-vi:": "1f1fb-1f1ee",
      ":flag-vn:": "1f1fb-1f1f3",
      ":flag-vu:": "1f1fb-1f1fa",
      ":w:": "1f1fc",
      ":flag-wf:": "1f1fc-1f1eb",
      ":flag-ws:": "1f1fc-1f1f8",
      ":x:": "1f1fd",
      ":flag-xk:": "1f1fd-1f1f0",
      ":y:": "1f1fe",
      ":flag-ye:": "1f1fe-1f1ea",
      ":flag-yt:": "1f1fe-1f1f9",
      ":z:": "1f1ff",
      ":flag-za:": "1f1ff-1f1e6",
      ":flag-zm:": "1f1ff-1f1f2",
      ":flag-zw:": "1f1ff-1f1fc",
      ":koko:": "1f201",
      ":sa:": "1f202",
      ":negation:": "1f21a",
      ":finger:": "1f22f",
      ":prohibit:": "1f232",
      ":empty:": "1f233",
      ":together:": "1f234",
      ":fullness:": "1f235",
      ":exist:": "1f236",
      ":moon:": "1f237",
      ":apply:": "1f238",
      ":divide:": "1f239",
      ":operating:": "1f23a",
      ":ideograph_advantage:": "1f250",
      ":accept:": "1f251",
      ":cyclone:": "1f300",
      ":foggy:": "1f301",
      ":closed_umbrella:": "1f302",
      ":night_with_stars:": "1f303",
      ":sunrise_over_mountains:": "1f304",
      ":sunrise:": "1f305",
      ":city_sunset:": "1f306",
      ":city_sunrise:": "1f307",
      ":rainbow:": "1f308",
      ":bridge_at_night:": "1f309",
      ":ocean:": "1f30a",
      ":volcano:": "1f30b",
      ":milky_way:": "1f30c",
      ":earth_africa:": "1f30d",
      ":earth_americas:": "1f30e",
      ":earth_asia:": "1f30f",
      ":globe_with_meridians:": "1f310",
      ":new_moon:": "1f311",
      ":waxing_crescent_moon:": "1f312",
      ":first_quarter_moon:": "1f313",
      ":waxing_gibbous_moon:": "1f314",
      ":full_moon:": "1f315",
      ":waning_gibbous_moon:": "1f316",
      ":last_quarter_moon:": "1f317",
      ":waning_crescent_moon:": "1f318",
      ":crescent_moon:": "1f319",
      ":new_moon_with_face:": "1f31a",
      ":first_quarter_moon_with_face:": "1f31b",
      ":last_quarter_moon_with_face:": "1f31c",
      ":full_moon_with_face:": "1f31d",
      ":sun_with_face:": "1f31e",
      ":star2:": "1f31f",
      ":stars:": "1f320",
      ":thermometer:": "1f321",
      ":mostly_sunny:": "1f324",
      ":barely_sunny:": "1f325",
      ":partly_sunny_rain:": "1f326",
      ":rain_cloud:": "1f327",
      ":snow_cloud:": "1f328",
      ":lightning:": "1f329",
      ":tornado:": "1f32a",
      ":fog:": "1f32b",
      ":wind_blowing_face:": "1f32c",
      ":hotdog:": "1f32d",
      ":taco:": "1f32e",
      ":burrito:": "1f32f",
      ":chestnut:": "1f330",
      ":seedling:": "1f331",
      ":evergreen_tree:": "1f332",
      ":deciduous_tree:": "1f333",
      ":palm_tree:": "1f334",
      ":cactus:": "1f335",
      ":hot_pepper:": "1f336",
      ":tulip:": "1f337",
      ":cherry_blossom:": "1f338",
      ":rose:": "1f339",
      ":hibiscus:": "1f33a",
      ":sunflower:": "1f33b",
      ":blossom:": "1f33c",
      ":corn:": "1f33d",
      ":ear_of_rice:": "1f33e",
      ":herb:": "1f33f",
      ":four_leaf_clover:": "1f340",
      ":maple_leaf:": "1f341",
      ":fallen_leaf:": "1f342",
      ":leaves:": "1f343",
      ":mushroom:": "1f344",
      ":tomato:": "1f345",
      ":eggplant:": "1f346",
      ":grapes:": "1f347",
      ":melon:": "1f348",
      ":watermelon:": "1f349",
      ":tangerine:": "1f34a",
      ":lemon:": "1f34b",
      ":banana:": "1f34c",
      ":pineapple:": "1f34d",
      ":apple:": "1f34e",
      ":green_apple:": "1f34f",
      ":pear:": "1f350",
      ":peach:": "1f351",
      ":cherries:": "1f352",
      ":strawberry:": "1f353",
      ":hamburger:": "1f354",
      ":pizza:": "1f355",
      ":meat_on_bone:": "1f356",
      ":poultry_leg:": "1f357",
      ":rice_cracker:": "1f358",
      ":rice_ball:": "1f359",
      ":rice:": "1f35a",
      ":curry:": "1f35b",
      ":ramen:": "1f35c",
      ":spaghetti:": "1f35d",
      ":bread:": "1f35e",
      ":fries:": "1f35f",
      ":sweet_potato:": "1f360",
      ":dango:": "1f361",
      ":oden:": "1f362",
      ":sushi:": "1f363",
      ":fried_shrimp:": "1f364",
      ":fish_cake:": "1f365",
      ":icecream:": "1f366",
      ":shaved_ice:": "1f367",
      ":ice_cream:": "1f368",
      ":doughnut:": "1f369",
      ":cookie:": "1f36a",
      ":chocolate_bar:": "1f36b",
      ":candy:": "1f36c",
      ":lollipop:": "1f36d",
      ":custard:": "1f36e",
      ":honey_pot:": "1f36f",
      ":cake:": "1f370",
      ":bento:": "1f371",
      ":stew:": "1f372",
      ":egg:": "1f373",
      ":fork_and_knife:": "1f374",
      ":tea:": "1f375",
      ":sake:": "1f376",
      ":wine_glass:": "1f377",
      ":cocktail:": "1f378",
      ":tropical_drink:": "1f379",
      ":beer:": "1f37a",
      ":beers:": "1f37b",
      ":baby_bottle:": "1f37c",
      ":knife_fork_plate:": "1f37d",
      ":champagne:": "1f37e",
      ":popcorn:": "1f37f",
      ":ribbon:": "1f380",
      ":gift:": "1f381",
      ":birthday:": "1f382",
      ":jack_o_lantern:": "1f383",
      ":christmas_tree:": "1f384",
      ":santa::skin-tone-2:": "1f385-1f3fb",
      ":santa::skin-tone-3:": "1f385-1f3fc",
      ":santa::skin-tone-4:": "1f385-1f3fd",
      ":santa::skin-tone-5:": "1f385-1f3fe",
      ":santa::skin-tone-6:": "1f385-1f3ff",
      ":santa:": "1f385",
      ":fireworks:": "1f386",
      ":sparkler:": "1f387",
      ":balloon:": "1f388",
      ":tada:": "1f389",
      ":confetti_ball:": "1f38a",
      ":tanabata_tree:": "1f38b",
      ":crossed_flags:": "1f38c",
      ":bamboo:": "1f38d",
      ":dolls:": "1f38e",
      ":flags:": "1f38f",
      ":wind_chime:": "1f390",
      ":rice_scene:": "1f391",
      ":school_satchel:": "1f392",
      ":mortar_board:": "1f393",
      ":medal:": "1f396",
      ":reminder_ribbon:": "1f397",
      ":studio_microphone:": "1f399",
      ":level_slider:": "1f39a",
      ":control_knobs:": "1f39b",
      ":film_frames:": "1f39e",
      ":admission_tickets:": "1f39f",
      ":carousel_horse:": "1f3a0",
      ":ferris_wheel:": "1f3a1",
      ":roller_coaster:": "1f3a2",
      ":fishing_pole_and_fish:": "1f3a3",
      ":microphone:": "1f3a4",
      ":movie_camera:": "1f3a5",
      ":cinema:": "1f3a6",
      ":headphones:": "1f3a7",
      ":art:": "1f3a8",
      ":tophat:": "1f3a9",
      ":circus_tent:": "1f3aa",
      ":ticket:": "1f3ab",
      ":clapper:": "1f3ac",
      ":performing_arts:": "1f3ad",
      ":video_game:": "1f3ae",
      ":dart:": "1f3af",
      ":slot_machine:": "1f3b0",
      ":8ball:": "1f3b1",
      ":game_die:": "1f3b2",
      ":bowling:": "1f3b3",
      ":flower_playing_cards:": "1f3b4",
      ":musical_note:": "1f3b5",
      ":notes:": "1f3b6",
      ":saxophone:": "1f3b7",
      ":guitar:": "1f3b8",
      ":musical_keyboard:": "1f3b9",
      ":trumpet:": "1f3ba",
      ":violin:": "1f3bb",
      ":musical_score:": "1f3bc",
      ":running_shirt_with_sash:": "1f3bd",
      ":tennis:": "1f3be",
      ":ski:": "1f3bf",
      ":basketball:": "1f3c0",
      ":checkered_flag:": "1f3c1",
      ":snowboarder::skin-tone-2:": "1f3c2-1f3fb",
      ":snowboarder::skin-tone-3:": "1f3c2-1f3fc",
      ":snowboarder::skin-tone-4:": "1f3c2-1f3fd",
      ":snowboarder::skin-tone-5:": "1f3c2-1f3fe",
      ":snowboarder::skin-tone-6:": "1f3c2-1f3ff",
      ":snowboarder:": "1f3c2",
      ":runner::skin-tone-2:": "1f3c3-1f3fb",
      ":runner::skin-tone-3:": "1f3c3-1f3fc",
      ":runner::skin-tone-4:": "1f3c3-1f3fd",
      ":runner::skin-tone-5:": "1f3c3-1f3fe",
      ":runner::skin-tone-6:": "1f3c3-1f3ff",
      ":runner:": "1f3c3",
      ":surfer::skin-tone-2:": "1f3c4-1f3fb",
      ":surfer::skin-tone-3:": "1f3c4-1f3fc",
      ":surfer::skin-tone-4:": "1f3c4-1f3fd",
      ":surfer::skin-tone-5:": "1f3c4-1f3fe",
      ":surfer::skin-tone-6:": "1f3c4-1f3ff",
      ":surfer:": "1f3c4",
      ":sports_medal:": "1f3c5",
      ":trophy:": "1f3c6",
      ":horse_racing::skin-tone-2:": "1f3c7-1f3fb",
      ":horse_racing::skin-tone-3:": "1f3c7-1f3fc",
      ":horse_racing::skin-tone-4:": "1f3c7-1f3fd",
      ":horse_racing::skin-tone-5:": "1f3c7-1f3fe",
      ":horse_racing::skin-tone-6:": "1f3c7-1f3ff",
      ":horse_racing:": "1f3c7",
      ":football:": "1f3c8",
      ":rugby_football:": "1f3c9",
      ":swimmer::skin-tone-2:": "1f3ca-1f3fb",
      ":swimmer::skin-tone-3:": "1f3ca-1f3fc",
      ":swimmer::skin-tone-4:": "1f3ca-1f3fd",
      ":swimmer::skin-tone-5:": "1f3ca-1f3fe",
      ":swimmer::skin-tone-6:": "1f3ca-1f3ff",
      ":swimmer:": "1f3ca",
      ":weight_lifter::skin-tone-2:": "1f3cb-1f3fb",
      ":weight_lifter::skin-tone-3:": "1f3cb-1f3fc",
      ":weight_lifter::skin-tone-4:": "1f3cb-1f3fd",
      ":weight_lifter::skin-tone-5:": "1f3cb-1f3fe",
      ":weight_lifter::skin-tone-6:": "1f3cb-1f3ff",
      ":weight_lifter:": "1f3cb",
      ":golfer:": "1f3cc",
      ":racing_motorcycle:": "1f3cd",
      ":racing_car:": "1f3ce",
      ":cricket_bat_and_ball:": "1f3cf",
      ":volleyball:": "1f3d0",
      ":field_hockey_stick_and_ball:": "1f3d1",
      ":ice_hockey_stick_and_puck:": "1f3d2",
      ":table_tennis_paddle_and_ball:": "1f3d3",
      ":snow_capped_mountain:": "1f3d4",
      ":camping:": "1f3d5",
      ":beach_with_umbrella:": "1f3d6",
      ":building_construction:": "1f3d7",
      ":house_buildings:": "1f3d8",
      ":cityscape:": "1f3d9",
      ":derelict_house_building:": "1f3da",
      ":classical_building:": "1f3db",
      ":desert:": "1f3dc",
      ":desert_island:": "1f3dd",
      ":national_park:": "1f3de",
      ":stadium:": "1f3df",
      ":house:": "1f3e0",
      ":house_with_garden:": "1f3e1",
      ":office:": "1f3e2",
      ":post_office:": "1f3e3",
      ":european_post_office:": "1f3e4",
      ":hospital:": "1f3e5",
      ":bank:": "1f3e6",
      ":atm:": "1f3e7",
      ":hotel:": "1f3e8",
      ":love_hotel:": "1f3e9",
      ":convenience_store:": "1f3ea",
      ":school:": "1f3eb",
      ":department_store:": "1f3ec",
      ":factory:": "1f3ed",
      ":izakaya_lantern:": "1f3ee",
      ":japanese_castle:": "1f3ef",
      ":european_castle:": "1f3f0",
      ":waving_white_flag:": "1f3f3",
      ":waving_black_flag:": "1f3f4",
      ":rosette:": "1f3f5",
      ":label:": "1f3f7",
      ":badminton_racquet_and_shuttlecock:": "1f3f8",
      ":bow_and_arrow:": "1f3f9",
      ":amphora:": "1f3fa",
      ":skin-tone-2:": "1f3fb",
      ":skin-tone-3:": "1f3fc",
      ":skin-tone-4:": "1f3fd",
      ":skin-tone-5:": "1f3fe",
      ":skin-tone-6:": "1f3ff",
      ":rat:": "1f400",
      ":mouse2:": "1f401",
      ":ox:": "1f402",
      ":water_buffalo:": "1f403",
      ":cow2:": "1f404",
      ":tiger2:": "1f405",
      ":leopard:": "1f406",
      ":rabbit2:": "1f407",
      ":cat2:": "1f408",
      ":dragon:": "1f409",
      ":crocodile:": "1f40a",
      ":whale2:": "1f40b",
      ":snail:": "1f40c",
      ":snake:": "1f40d",
      ":racehorse:": "1f40e",
      ":ram:": "1f40f",
      ":goat:": "1f410",
      ":sheep:": "1f411",
      ":monkey:": "1f412",
      ":rooster:": "1f413",
      ":chicken:": "1f414",
      ":dog2:": "1f415",
      ":pig2:": "1f416",
      ":boar:": "1f417",
      ":elephant:": "1f418",
      ":octopus:": "1f419",
      ":shell:": "1f41a",
      ":bug:": "1f41b",
      ":ant:": "1f41c",
      ":bee:": "1f41d",
      ":beetle:": "1f41e",
      ":fish:": "1f41f",
      ":tropical_fish:": "1f420",
      ":blowfish:": "1f421",
      ":turtle:": "1f422",
      ":hatching_chick:": "1f423",
      ":baby_chick:": "1f424",
      ":hatched_chick:": "1f425",
      ":bird:": "1f426",
      ":penguin:": "1f427",
      ":koala:": "1f428",
      ":poodle:": "1f429",
      ":dromedary_camel:": "1f42a",
      ":camel:": "1f42b",
      ":dolphin:": "1f42c",
      ":mouse:": "1f42d",
      ":cow:": "1f42e",
      ":tiger:": "1f42f",
      ":rabbit:": "1f430",
      ":cat:": "1f431",
      ":dragon_face:": "1f432",
      ":whale:": "1f433",
      ":horse:": "1f434",
      ":monkey_face:": "1f435",
      ":dog:": "1f436",
      ":pig:": "1f437",
      ":frog:": "1f438",
      ":hamster:": "1f439",
      ":wolf:": "1f43a",
      ":bear:": "1f43b",
      ":panda_face:": "1f43c",
      ":pig_nose:": "1f43d",
      ":feet:": "1f43e",
      ":chipmunk:": "1f43f",
      ":eyes:": "1f440",
      ":eye:": "1f441",
      ":eye::left_speech_bubble:": "1f441-200d-1f5e8",
      ":ear::skin-tone-2:": "1f442-1f3fb",
      ":ear::skin-tone-3:": "1f442-1f3fc",
      ":ear::skin-tone-4:": "1f442-1f3fd",
      ":ear::skin-tone-5:": "1f442-1f3fe",
      ":ear::skin-tone-6:": "1f442-1f3ff",
      ":ear:": "1f442",
      ":nose::skin-tone-2:": "1f443-1f3fb",
      ":nose::skin-tone-3:": "1f443-1f3fc",
      ":nose::skin-tone-4:": "1f443-1f3fd",
      ":nose::skin-tone-5:": "1f443-1f3fe",
      ":nose::skin-tone-6:": "1f443-1f3ff",
      ":nose:": "1f443",
      ":lips:": "1f444",
      ":tongue:": "1f445",
      ":point_up_2::skin-tone-2:": "1f446-1f3fb",
      ":point_up_2::skin-tone-3:": "1f446-1f3fc",
      ":point_up_2::skin-tone-4:": "1f446-1f3fd",
      ":point_up_2::skin-tone-5:": "1f446-1f3fe",
      ":point_up_2::skin-tone-6:": "1f446-1f3ff",
      ":point_up_2:": "1f446",
      ":point_down::skin-tone-2:": "1f447-1f3fb",
      ":point_down::skin-tone-3:": "1f447-1f3fc",
      ":point_down::skin-tone-4:": "1f447-1f3fd",
      ":point_down::skin-tone-5:": "1f447-1f3fe",
      ":point_down::skin-tone-6:": "1f447-1f3ff",
      ":point_down:": "1f447",
      ":point_left::skin-tone-2:": "1f448-1f3fb",
      ":point_left::skin-tone-3:": "1f448-1f3fc",
      ":point_left::skin-tone-4:": "1f448-1f3fd",
      ":point_left::skin-tone-5:": "1f448-1f3fe",
      ":point_left::skin-tone-6:": "1f448-1f3ff",
      ":point_left:": "1f448",
      ":point_right::skin-tone-2:": "1f449-1f3fb",
      ":point_right::skin-tone-3:": "1f449-1f3fc",
      ":point_right::skin-tone-4:": "1f449-1f3fd",
      ":point_right::skin-tone-5:": "1f449-1f3fe",
      ":point_right::skin-tone-6:": "1f449-1f3ff",
      ":point_right:": "1f449",
      ":facepunch::skin-tone-2:": "1f44a-1f3fb",
      ":facepunch::skin-tone-3:": "1f44a-1f3fc",
      ":facepunch::skin-tone-4:": "1f44a-1f3fd",
      ":facepunch::skin-tone-5:": "1f44a-1f3fe",
      ":facepunch::skin-tone-6:": "1f44a-1f3ff",
      ":facepunch:": "1f44a",
      ":wave::skin-tone-2:": "1f44b-1f3fb",
      ":wave::skin-tone-3:": "1f44b-1f3fc",
      ":wave::skin-tone-4:": "1f44b-1f3fd",
      ":wave::skin-tone-5:": "1f44b-1f3fe",
      ":wave::skin-tone-6:": "1f44b-1f3ff",
      ":wave:": "1f44b",
      ":ok_hand::skin-tone-2:": "1f44c-1f3fb",
      ":ok_hand::skin-tone-3:": "1f44c-1f3fc",
      ":ok_hand::skin-tone-4:": "1f44c-1f3fd",
      ":ok_hand::skin-tone-5:": "1f44c-1f3fe",
      ":ok_hand::skin-tone-6:": "1f44c-1f3ff",
      ":ok_hand:": "1f44c",
      ":+1::skin-tone-2:": "1f44d-1f3fb",
      ":+1::skin-tone-3:": "1f44d-1f3fc",
      ":+1::skin-tone-4:": "1f44d-1f3fd",
      ":+1::skin-tone-5:": "1f44d-1f3fe",
      ":+1::skin-tone-6:": "1f44d-1f3ff",
      ":+1:": "1f44d",
      ":-1::skin-tone-2:": "1f44e-1f3fb",
      ":-1::skin-tone-3:": "1f44e-1f3fc",
      ":-1::skin-tone-4:": "1f44e-1f3fd",
      ":-1::skin-tone-5:": "1f44e-1f3fe",
      ":-1::skin-tone-6:": "1f44e-1f3ff",
      ":-1:": "1f44e",
      ":clap::skin-tone-2:": "1f44f-1f3fb",
      ":clap::skin-tone-3:": "1f44f-1f3fc",
      ":clap::skin-tone-4:": "1f44f-1f3fd",
      ":clap::skin-tone-5:": "1f44f-1f3fe",
      ":clap::skin-tone-6:": "1f44f-1f3ff",
      ":clap:": "1f44f",
      ":open_hands::skin-tone-2:": "1f450-1f3fb",
      ":open_hands::skin-tone-3:": "1f450-1f3fc",
      ":open_hands::skin-tone-4:": "1f450-1f3fd",
      ":open_hands::skin-tone-5:": "1f450-1f3fe",
      ":open_hands::skin-tone-6:": "1f450-1f3ff",
      ":open_hands:": "1f450",
      ":crown:": "1f451",
      ":womans_hat:": "1f452",
      ":eyeglasses:": "1f453",
      ":necktie:": "1f454",
      ":shirt:": "1f455",
      ":jeans:": "1f456",
      ":dress:": "1f457",
      ":kimono:": "1f458",
      ":bikini:": "1f459",
      ":womans_clothes:": "1f45a",
      ":purse:": "1f45b",
      ":handbag:": "1f45c",
      ":pouch:": "1f45d",
      ":mans_shoe:": "1f45e",
      ":athletic_shoe:": "1f45f",
      ":high_heel:": "1f460",
      ":sandal:": "1f461",
      ":boot:": "1f462",
      ":footprints:": "1f463",
      ":bust_in_silhouette:": "1f464",
      ":busts_in_silhouette:": "1f465",
      ":boy::skin-tone-2:": "1f466-1f3fb",
      ":boy::skin-tone-3:": "1f466-1f3fc",
      ":boy::skin-tone-4:": "1f466-1f3fd",
      ":boy::skin-tone-5:": "1f466-1f3fe",
      ":boy::skin-tone-6:": "1f466-1f3ff",
      ":boy:": "1f466",
      ":girl::skin-tone-2:": "1f467-1f3fb",
      ":girl::skin-tone-3:": "1f467-1f3fc",
      ":girl::skin-tone-4:": "1f467-1f3fd",
      ":girl::skin-tone-5:": "1f467-1f3fe",
      ":girl::skin-tone-6:": "1f467-1f3ff",
      ":girl:": "1f467",
      ":man::skin-tone-2:": "1f468-1f3fb",
      ":man::skin-tone-3:": "1f468-1f3fc",
      ":man::skin-tone-4:": "1f468-1f3fd",
      ":man::skin-tone-5:": "1f468-1f3fe",
      ":man::skin-tone-6:": "1f468-1f3ff",
      ":man:": "1f468",
      ":man-man-boy:": "1f468-200d-1f468-200d-1f466",
      ":man-man-boy-boy:": "1f468-200d-1f468-200d-1f466-200d-1f466",
      ":man-man-girl:": "1f468-200d-1f468-200d-1f467",
      ":man-man-girl-boy:": "1f468-200d-1f468-200d-1f467-200d-1f466",
      ":man-man-girl-girl:": "1f468-200d-1f468-200d-1f467-200d-1f467",
      ":man-woman-boy:": "1f468-200d-1f469-200d-1f466",
      ":man-woman-boy-boy:": "1f468-200d-1f469-200d-1f466-200d-1f466",
      ":man-woman-girl:": "1f468-200d-1f469-200d-1f467",
      ":man-woman-girl-boy:": "1f468-200d-1f469-200d-1f467-200d-1f466",
      ":man-woman-girl-girl:": "1f468-200d-1f469-200d-1f467-200d-1f467",
      ":man-heart-man:": "1f468-200d-2764-fe0f-200d-1f468",
      ":man-kiss-man:": "1f468-200d-2764-fe0f-200d-1f48b-200d-1f468",
      ":woman::skin-tone-2:": "1f469-1f3fb",
      ":woman::skin-tone-3:": "1f469-1f3fc",
      ":woman::skin-tone-4:": "1f469-1f3fd",
      ":woman::skin-tone-5:": "1f469-1f3fe",
      ":woman::skin-tone-6:": "1f469-1f3ff",
      ":woman:": "1f469",
      ":woman-woman-boy:": "1f469-200d-1f469-200d-1f466",
      ":woman-woman-boy-boy:": "1f469-200d-1f469-200d-1f466-200d-1f466",
      ":woman-woman-girl:": "1f469-200d-1f469-200d-1f467",
      ":woman-woman-girl-boy:": "1f469-200d-1f469-200d-1f467-200d-1f466",
      ":woman-woman-girl-girl:": "1f469-200d-1f469-200d-1f467-200d-1f467",
      ":woman-heart-man:": "1f469-200d-2764-fe0f-200d-1f468",
      ":woman-heart-woman:": "1f469-200d-2764-fe0f-200d-1f469",
      ":woman-kiss-man:": "1f469-200d-2764-fe0f-200d-1f48b-200d-1f468",
      ":woman-kiss-woman:": "1f469-200d-2764-fe0f-200d-1f48b-200d-1f469",
      ":family:": "1f46a",
      ":couple:": "1f46b",
      ":two_men_holding_hands:": "1f46c",
      ":two_women_holding_hands:": "1f46d",
      ":cop::skin-tone-2:": "1f46e-1f3fb",
      ":cop::skin-tone-3:": "1f46e-1f3fc",
      ":cop::skin-tone-4:": "1f46e-1f3fd",
      ":cop::skin-tone-5:": "1f46e-1f3fe",
      ":cop::skin-tone-6:": "1f46e-1f3ff",
      ":cop:": "1f46e",
      ":dancers:": "1f46f",
      ":bride_with_veil::skin-tone-2:": "1f470-1f3fb",
      ":bride_with_veil::skin-tone-3:": "1f470-1f3fc",
      ":bride_with_veil::skin-tone-4:": "1f470-1f3fd",
      ":bride_with_veil::skin-tone-5:": "1f470-1f3fe",
      ":bride_with_veil::skin-tone-6:": "1f470-1f3ff",
      ":bride_with_veil:": "1f470",
      ":person_with_blond_hair::skin-tone-2:": "1f471-1f3fb",
      ":person_with_blond_hair::skin-tone-3:": "1f471-1f3fc",
      ":person_with_blond_hair::skin-tone-4:": "1f471-1f3fd",
      ":person_with_blond_hair::skin-tone-5:": "1f471-1f3fe",
      ":person_with_blond_hair::skin-tone-6:": "1f471-1f3ff",
      ":person_with_blond_hair:": "1f471",
      ":man_with_gua_pi_mao::skin-tone-2:": "1f472-1f3fb",
      ":man_with_gua_pi_mao::skin-tone-3:": "1f472-1f3fc",
      ":man_with_gua_pi_mao::skin-tone-4:": "1f472-1f3fd",
      ":man_with_gua_pi_mao::skin-tone-5:": "1f472-1f3fe",
      ":man_with_gua_pi_mao::skin-tone-6:": "1f472-1f3ff",
      ":man_with_gua_pi_mao:": "1f472",
      ":man_with_turban::skin-tone-2:": "1f473-1f3fb",
      ":man_with_turban::skin-tone-3:": "1f473-1f3fc",
      ":man_with_turban::skin-tone-4:": "1f473-1f3fd",
      ":man_with_turban::skin-tone-5:": "1f473-1f3fe",
      ":man_with_turban::skin-tone-6:": "1f473-1f3ff",
      ":man_with_turban:": "1f473",
      ":older_man::skin-tone-2:": "1f474-1f3fb",
      ":older_man::skin-tone-3:": "1f474-1f3fc",
      ":older_man::skin-tone-4:": "1f474-1f3fd",
      ":older_man::skin-tone-5:": "1f474-1f3fe",
      ":older_man::skin-tone-6:": "1f474-1f3ff",
      ":older_man:": "1f474",
      ":older_woman::skin-tone-2:": "1f475-1f3fb",
      ":older_woman::skin-tone-3:": "1f475-1f3fc",
      ":older_woman::skin-tone-4:": "1f475-1f3fd",
      ":older_woman::skin-tone-5:": "1f475-1f3fe",
      ":older_woman::skin-tone-6:": "1f475-1f3ff",
      ":older_woman:": "1f475",
      ":baby::skin-tone-2:": "1f476-1f3fb",
      ":baby::skin-tone-3:": "1f476-1f3fc",
      ":baby::skin-tone-4:": "1f476-1f3fd",
      ":baby::skin-tone-5:": "1f476-1f3fe",
      ":baby::skin-tone-6:": "1f476-1f3ff",
      ":baby:": "1f476",
      ":construction_worker::skin-tone-2:": "1f477-1f3fb",
      ":construction_worker::skin-tone-3:": "1f477-1f3fc",
      ":construction_worker::skin-tone-4:": "1f477-1f3fd",
      ":construction_worker::skin-tone-5:": "1f477-1f3fe",
      ":construction_worker::skin-tone-6:": "1f477-1f3ff",
      ":construction_worker:": "1f477",
      ":princess::skin-tone-2:": "1f478-1f3fb",
      ":princess::skin-tone-3:": "1f478-1f3fc",
      ":princess::skin-tone-4:": "1f478-1f3fd",
      ":princess::skin-tone-5:": "1f478-1f3fe",
      ":princess::skin-tone-6:": "1f478-1f3ff",
      ":princess:": "1f478",
      ":japanese_ogre:": "1f479",
      ":japanese_goblin:": "1f47a",
      ":ghost:": "1f47b",
      ":angel::skin-tone-2:": "1f47c-1f3fb",
      ":angel::skin-tone-3:": "1f47c-1f3fc",
      ":angel::skin-tone-4:": "1f47c-1f3fd",
      ":angel::skin-tone-5:": "1f47c-1f3fe",
      ":angel::skin-tone-6:": "1f47c-1f3ff",
      ":angel:": "1f47c",
      ":alien:": "1f47d",
      ":space_invader:": "1f47e",
      ":imp:": "1f47f",
      ":skull:": "1f480",
      ":information_desk_person::skin-tone-2:": "1f481-1f3fb",
      ":information_desk_person::skin-tone-3:": "1f481-1f3fc",
      ":information_desk_person::skin-tone-4:": "1f481-1f3fd",
      ":information_desk_person::skin-tone-5:": "1f481-1f3fe",
      ":information_desk_person::skin-tone-6:": "1f481-1f3ff",
      ":information_desk_person:": "1f481",
      ":guardsman::skin-tone-2:": "1f482-1f3fb",
      ":guardsman::skin-tone-3:": "1f482-1f3fc",
      ":guardsman::skin-tone-4:": "1f482-1f3fd",
      ":guardsman::skin-tone-5:": "1f482-1f3fe",
      ":guardsman::skin-tone-6:": "1f482-1f3ff",
      ":guardsman:": "1f482",
      ":dancer::skin-tone-2:": "1f483-1f3fb",
      ":dancer::skin-tone-3:": "1f483-1f3fc",
      ":dancer::skin-tone-4:": "1f483-1f3fd",
      ":dancer::skin-tone-5:": "1f483-1f3fe",
      ":dancer::skin-tone-6:": "1f483-1f3ff",
      ":dancer:": "1f483",
      ":lipstick:": "1f484",
      ":nail_care::skin-tone-2:": "1f485-1f3fb",
      ":nail_care::skin-tone-3:": "1f485-1f3fc",
      ":nail_care::skin-tone-4:": "1f485-1f3fd",
      ":nail_care::skin-tone-5:": "1f485-1f3fe",
      ":nail_care::skin-tone-6:": "1f485-1f3ff",
      ":nail_care:": "1f485",
      ":massage::skin-tone-2:": "1f486-1f3fb",
      ":massage::skin-tone-3:": "1f486-1f3fc",
      ":massage::skin-tone-4:": "1f486-1f3fd",
      ":massage::skin-tone-5:": "1f486-1f3fe",
      ":massage::skin-tone-6:": "1f486-1f3ff",
      ":massage:": "1f486",
      ":haircut::skin-tone-2:": "1f487-1f3fb",
      ":haircut::skin-tone-3:": "1f487-1f3fc",
      ":haircut::skin-tone-4:": "1f487-1f3fd",
      ":haircut::skin-tone-5:": "1f487-1f3fe",
      ":haircut::skin-tone-6:": "1f487-1f3ff",
      ":haircut:": "1f487",
      ":barber:": "1f488",
      ":syringe:": "1f489",
      ":pill:": "1f48a",
      ":kiss:": "1f48b",
      ":love_letter:": "1f48c",
      ":ring:": "1f48d",
      ":gem:": "1f48e",
      ":couplekiss:": "1f48f",
      ":bouquet:": "1f490",
      ":couple_with_heart:": "1f491",
      ":wedding:": "1f492",
      ":heartbeat:": "1f493",
      ":broken_heart:": "1f494",
      ":two_hearts:": "1f495",
      ":sparkling_heart:": "1f496",
      ":heartpulse:": "1f497",
      ":cupid:": "1f498",
      ":blue_heart:": "1f499",
      ":green_heart:": "1f49a",
      ":yellow_heart:": "1f49b",
      ":purple_heart:": "1f49c",
      ":gift_heart:": "1f49d",
      ":revolving_hearts:": "1f49e",
      ":heart_decoration:": "1f49f",
      ":diamond_shape_with_a_dot_inside:": "1f4a0",
      ":bulb:": "1f4a1",
      ":anger:": "1f4a2",
      ":bomb:": "1f4a3",
      ":zzz:": "1f4a4",
      ":boom:": "1f4a5",
      ":sweat_drops:": "1f4a6",
      ":droplet:": "1f4a7",
      ":dash:": "1f4a8",
      ":hankey:": "1f4a9",
      ":muscle::skin-tone-2:": "1f4aa-1f3fb",
      ":muscle::skin-tone-3:": "1f4aa-1f3fc",
      ":muscle::skin-tone-4:": "1f4aa-1f3fd",
      ":muscle::skin-tone-5:": "1f4aa-1f3fe",
      ":muscle::skin-tone-6:": "1f4aa-1f3ff",
      ":muscle:": "1f4aa",
      ":dizzy:": "1f4ab",
      ":speech_balloon:": "1f4ac",
      ":thought_balloon:": "1f4ad",
      ":white_flower:": "1f4ae",
      ":100:": "1f4af",
      ":moneybag:": "1f4b0",
      ":currency_exchange:": "1f4b1",
      ":heavy_dollar_sign:": "1f4b2",
      ":credit_card:": "1f4b3",
      ":yen:": "1f4b4",
      ":dollar:": "1f4b5",
      ":euro:": "1f4b6",
      ":pound:": "1f4b7",
      ":money_with_wings:": "1f4b8",
      ":chart:": "1f4b9",
      ":seat:": "1f4ba",
      ":computer:": "1f4bb",
      ":briefcase:": "1f4bc",
      ":minidisc:": "1f4bd",
      ":floppy_disk:": "1f4be",
      ":cd:": "1f4bf",
      ":dvd:": "1f4c0",
      ":file_folder:": "1f4c1",
      ":open_file_folder:": "1f4c2",
      ":page_with_curl:": "1f4c3",
      ":page_facing_up:": "1f4c4",
      ":date:": "1f4c5",
      ":calendar:": "1f4c6",
      ":card_index:": "1f4c7",
      ":chart_with_upwards_trend:": "1f4c8",
      ":chart_with_downwards_trend:": "1f4c9",
      ":bar_chart:": "1f4ca",
      ":clipboard:": "1f4cb",
      ":pushpin:": "1f4cc",
      ":round_pushpin:": "1f4cd",
      ":paperclip:": "1f4ce",
      ":straight_ruler:": "1f4cf",
      ":triangular_ruler:": "1f4d0",
      ":bookmark_tabs:": "1f4d1",
      ":ledger:": "1f4d2",
      ":notebook:": "1f4d3",
      ":notebook_with_decorative_cover:": "1f4d4",
      ":closed_book:": "1f4d5",
      ":book:": "1f4d6",
      ":green_book:": "1f4d7",
      ":blue_book:": "1f4d8",
      ":orange_book:": "1f4d9",
      ":books:": "1f4da",
      ":name_badge:": "1f4db",
      ":scroll:": "1f4dc",
      ":memo:": "1f4dd",
      ":telephone_receiver:": "1f4de",
      ":pager:": "1f4df",
      ":fax:": "1f4e0",
      ":satellite_antenna:": "1f4e1",
      ":loudspeaker:": "1f4e2",
      ":mega:": "1f4e3",
      ":outbox_tray:": "1f4e4",
      ":inbox_tray:": "1f4e5",
      ":package:": "1f4e6",
      ":e-mail:": "1f4e7",
      ":incoming_envelope:": "1f4e8",
      ":envelope_with_arrow:": "1f4e9",
      ":mailbox_closed:": "1f4ea",
      ":mailbox:": "1f4eb",
      ":mailbox_with_mail:": "1f4ec",
      ":mailbox_with_no_mail:": "1f4ed",
      ":postbox:": "1f4ee",
      ":postal_horn:": "1f4ef",
      ":newspaper:": "1f4f0",
      ":iphone:": "1f4f1",
      ":calling:": "1f4f2",
      ":vibration_mode:": "1f4f3",
      ":mobile_phone_off:": "1f4f4",
      ":no_mobile_phones:": "1f4f5",
      ":signal_strength:": "1f4f6",
      ":camera:": "1f4f7",
      ":camera_with_flash:": "1f4f8",
      ":video_camera:": "1f4f9",
      ":tv:": "1f4fa",
      ":radio:": "1f4fb",
      ":vhs:": "1f4fc",
      ":film_projector:": "1f4fd",
      ":prayer_beads:": "1f4ff",
      ":twisted_rightwards_arrows:": "1f500",
      ":repeat:": "1f501",
      ":repeat_one:": "1f502",
      ":arrows_clockwise:": "1f503",
      ":arrows_counterclockwise:": "1f504",
      ":low_brightness:": "1f505",
      ":high_brightness:": "1f506",
      ":mute:": "1f507",
      ":speaker:": "1f508",
      ":sound:": "1f509",
      ":loud_sound:": "1f50a",
      ":battery:": "1f50b",
      ":electric_plug:": "1f50c",
      ":mag:": "1f50d",
      ":mag_right:": "1f50e",
      ":lock_with_ink_pen:": "1f50f",
      ":closed_lock_with_key:": "1f510",
      ":key:": "1f511",
      ":lock:": "1f512",
      ":unlock:": "1f513",
      ":bell:": "1f514",
      ":no_bell:": "1f515",
      ":bookmark:": "1f516",
      ":link:": "1f517",
      ":radio_button:": "1f518",
      ":back:": "1f519",
      ":end:": "1f51a",
      ":on:": "1f51b",
      ":soon:": "1f51c",
      ":top:": "1f51d",
      ":underage:": "1f51e",
      ":keycap_ten:": "1f51f",
      ":capital_abcd:": "1f520",
      ":abcd:": "1f521",
      ":1234:": "1f522",
      ":symbols:": "1f523",
      ":abc:": "1f524",
      ":fire:": "1f525",
      ":flashlight:": "1f526",
      ":wrench:": "1f527",
      ":hammer:": "1f528",
      ":nut_and_bolt:": "1f529",
      ":hocho:": "1f52a",
      ":gun:": "1f52b",
      ":microscope:": "1f52c",
      ":telescope:": "1f52d",
      ":crystal_ball:": "1f52e",
      ":six_pointed_star:": "1f52f",
      ":beginner:": "1f530",
      ":trident:": "1f531",
      ":black_square_button:": "1f532",
      ":white_square_button:": "1f533",
      ":red_circle:": "1f534",
      ":large_blue_circle:": "1f535",
      ":large_orange_diamond:": "1f536",
      ":large_blue_diamond:": "1f537",
      ":small_orange_diamond:": "1f538",
      ":small_blue_diamond:": "1f539",
      ":small_red_triangle:": "1f53a",
      ":small_red_triangle_down:": "1f53b",
      ":arrow_up_small:": "1f53c",
      ":arrow_down_small:": "1f53d",
      ":om_symbol:": "1f549",
      ":dove_of_peace:": "1f54a",
      ":kaaba:": "1f54b",
      ":mosque:": "1f54c",
      ":synagogue:": "1f54d",
      ":menorah_with_nine_branches:": "1f54e",
      ":clock1:": "1f550",
      ":clock2:": "1f551",
      ":clock3:": "1f552",
      ":clock4:": "1f553",
      ":clock5:": "1f554",
      ":clock6:": "1f555",
      ":clock7:": "1f556",
      ":clock8:": "1f557",
      ":clock9:": "1f558",
      ":clock10:": "1f559",
      ":clock11:": "1f55a",
      ":clock12:": "1f55b",
      ":clock130:": "1f55c",
      ":clock230:": "1f55d",
      ":clock330:": "1f55e",
      ":clock430:": "1f55f",
      ":clock530:": "1f560",
      ":clock630:": "1f561",
      ":clock730:": "1f562",
      ":clock830:": "1f563",
      ":clock930:": "1f564",
      ":clock1030:": "1f565",
      ":clock1130:": "1f566",
      ":clock1230:": "1f567",
      ":candle:": "1f56f",
      ":mantelpiece_clock:": "1f570",
      ":hole:": "1f573",
      ":man_in_business_suit_levitating:": "1f574",
      ":sleuth_or_spy::skin-tone-2:": "1f575-1f3fb",
      ":sleuth_or_spy::skin-tone-3:": "1f575-1f3fc",
      ":sleuth_or_spy::skin-tone-4:": "1f575-1f3fd",
      ":sleuth_or_spy::skin-tone-5:": "1f575-1f3fe",
      ":sleuth_or_spy::skin-tone-6:": "1f575-1f3ff",
      ":sleuth_or_spy:": "1f575",
      ":dark_sunglasses:": "1f576",
      ":spider:": "1f577",
      ":spider_web:": "1f578",
      ":joystick:": "1f579",
      ":linked_paperclips:": "1f587",
      ":lower_left_ballpoint_pen:": "1f58a",
      ":lower_left_fountain_pen:": "1f58b",
      ":lower_left_paintbrush:": "1f58c",
      ":lower_left_crayon:": "1f58d",
      ":raised_hand_with_fingers_splayed::skin-tone-2:": "1f590-1f3fb",
      ":raised_hand_with_fingers_splayed::skin-tone-3:": "1f590-1f3fc",
      ":raised_hand_with_fingers_splayed::skin-tone-4:": "1f590-1f3fd",
      ":raised_hand_with_fingers_splayed::skin-tone-5:": "1f590-1f3fe",
      ":raised_hand_with_fingers_splayed::skin-tone-6:": "1f590-1f3ff",
      ":raised_hand_with_fingers_splayed:": "1f590",
      ":middle_finger::skin-tone-2:": "1f595-1f3fb",
      ":middle_finger::skin-tone-3:": "1f595-1f3fc",
      ":middle_finger::skin-tone-4:": "1f595-1f3fd",
      ":middle_finger::skin-tone-5:": "1f595-1f3fe",
      ":middle_finger::skin-tone-6:": "1f595-1f3ff",
      ":middle_finger:": "1f595",
      ":spock-hand::skin-tone-2:": "1f596-1f3fb",
      ":spock-hand::skin-tone-3:": "1f596-1f3fc",
      ":spock-hand::skin-tone-4:": "1f596-1f3fd",
      ":spock-hand::skin-tone-5:": "1f596-1f3fe",
      ":spock-hand::skin-tone-6:": "1f596-1f3ff",
      ":spock-hand:": "1f596",
      ":desktop_computer:": "1f5a5",
      ":printer:": "1f5a8",
      ":three_button_mouse:": "1f5b1",
      ":trackball:": "1f5b2",
      ":frame_with_picture:": "1f5bc",
      ":card_index_dividers:": "1f5c2",
      ":card_file_box:": "1f5c3",
      ":file_cabinet:": "1f5c4",
      ":wastebasket:": "1f5d1",
      ":spiral_note_pad:": "1f5d2",
      ":spiral_calendar_pad:": "1f5d3",
      ":compression:": "1f5dc",
      ":old_key:": "1f5dd",
      ":rolled_up_newspaper:": "1f5de",
      ":dagger_knife:": "1f5e1",
      ":speaking_head_in_silhouette:": "1f5e3",
      ":left_speech_bubble:": "1f5e8",
      ":right_anger_bubble:": "1f5ef",
      ":ballot_box_with_ballot:": "1f5f3",
      ":world_map:": "1f5fa",
      ":mount_fuji:": "1f5fb",
      ":tokyo_tower:": "1f5fc",
      ":statue_of_liberty:": "1f5fd",
      ":japan:": "1f5fe",
      ":moyai:": "1f5ff",
      ":grinning:": "1f600",
      ":grin:": "1f601",
      ":joy:": "1f602",
      ":smiley:": "1f603",
      ":smile:": "1f604",
      ":sweat_smile:": "1f605",
      ":laughing:": "1f606",
      ":innocent:": "1f607",
      ":smiling_imp:": "1f608",
      ":wink:": "1f609",
      ":blush:": "1f60a",
      ":yum:": "1f60b",
      ":relieved:": "1f60c",
      ":heart_eyes:": "1f60d",
      ":sunglasses:": "1f60e",
      ":smirk:": "1f60f",
      ":neutral_face:": "1f610",
      ":expressionless:": "1f611",
      ":unamused:": "1f612",
      ":sweat:": "1f613",
      ":pensive:": "1f614",
      ":confused:": "1f615",
      ":confounded:": "1f616",
      ":kissing:": "1f617",
      ":kissing_heart:": "1f618",
      ":kissing_smiling_eyes:": "1f619",
      ":kissing_closed_eyes:": "1f61a",
      ":stuck_out_tongue:": "1f61b",
      ":stuck_out_tongue_winking_eye:": "1f61c",
      ":stuck_out_tongue_closed_eyes:": "1f61d",
      ":disappointed:": "1f61e",
      ":worried:": "1f61f",
      ":angry:": "1f620",
      ":rage:": "1f621",
      ":cry:": "1f622",
      ":persevere:": "1f623",
      ":triumph:": "1f624",
      ":disappointed_relieved:": "1f625",
      ":frowning:": "1f626",
      ":anguished:": "1f627",
      ":fearful:": "1f628",
      ":weary:": "1f629",
      ":sleepy:": "1f62a",
      ":tired_face:": "1f62b",
      ":grimacing:": "1f62c",
      ":sob:": "1f62d",
      ":open_mouth:": "1f62e",
      ":hushed:": "1f62f",
      ":cold_sweat:": "1f630",
      ":scream:": "1f631",
      ":astonished:": "1f632",
      ":flushed:": "1f633",
      ":sleeping:": "1f634",
      ":dizzy_face:": "1f635",
      ":no_mouth:": "1f636",
      ":mask:": "1f637",
      ":smile_cat:": "1f638",
      ":joy_cat:": "1f639",
      ":smiley_cat:": "1f63a",
      ":heart_eyes_cat:": "1f63b",
      ":smirk_cat:": "1f63c",
      ":kissing_cat:": "1f63d",
      ":pouting_cat:": "1f63e",
      ":crying_cat_face:": "1f63f",
      ":scream_cat:": "1f640",
      ":slightly_frowning_face:": "1f641",
      ":slightly_smiling_face:": "1f642",
      ":upside_down_face:": "1f643",
      ":face_with_rolling_eyes:": "1f644",
      ":no_good::skin-tone-2:": "1f645-1f3fb",
      ":no_good::skin-tone-3:": "1f645-1f3fc",
      ":no_good::skin-tone-4:": "1f645-1f3fd",
      ":no_good::skin-tone-5:": "1f645-1f3fe",
      ":no_good::skin-tone-6:": "1f645-1f3ff",
      ":no_good:": "1f645",
      ":ok_woman::skin-tone-2:": "1f646-1f3fb",
      ":ok_woman::skin-tone-3:": "1f646-1f3fc",
      ":ok_woman::skin-tone-4:": "1f646-1f3fd",
      ":ok_woman::skin-tone-5:": "1f646-1f3fe",
      ":ok_woman::skin-tone-6:": "1f646-1f3ff",
      ":ok_woman:": "1f646",
      ":bow::skin-tone-2:": "1f647-1f3fb",
      ":bow::skin-tone-3:": "1f647-1f3fc",
      ":bow::skin-tone-4:": "1f647-1f3fd",
      ":bow::skin-tone-5:": "1f647-1f3fe",
      ":bow::skin-tone-6:": "1f647-1f3ff",
      ":bow:": "1f647",
      ":see_no_evil:": "1f648",
      ":hear_no_evil:": "1f649",
      ":speak_no_evil:": "1f64a",
      ":raising_hand::skin-tone-2:": "1f64b-1f3fb",
      ":raising_hand::skin-tone-3:": "1f64b-1f3fc",
      ":raising_hand::skin-tone-4:": "1f64b-1f3fd",
      ":raising_hand::skin-tone-5:": "1f64b-1f3fe",
      ":raising_hand::skin-tone-6:": "1f64b-1f3ff",
      ":raising_hand:": "1f64b",
      ":raised_hands::skin-tone-2:": "1f64c-1f3fb",
      ":raised_hands::skin-tone-3:": "1f64c-1f3fc",
      ":raised_hands::skin-tone-4:": "1f64c-1f3fd",
      ":raised_hands::skin-tone-5:": "1f64c-1f3fe",
      ":raised_hands::skin-tone-6:": "1f64c-1f3ff",
      ":raised_hands:": "1f64c",
      ":person_frowning::skin-tone-2:": "1f64d-1f3fb",
      ":person_frowning::skin-tone-3:": "1f64d-1f3fc",
      ":person_frowning::skin-tone-4:": "1f64d-1f3fd",
      ":person_frowning::skin-tone-5:": "1f64d-1f3fe",
      ":person_frowning::skin-tone-6:": "1f64d-1f3ff",
      ":person_frowning:": "1f64d",
      ":person_with_pouting_face::skin-tone-2:": "1f64e-1f3fb",
      ":person_with_pouting_face::skin-tone-3:": "1f64e-1f3fc",
      ":person_with_pouting_face::skin-tone-4:": "1f64e-1f3fd",
      ":person_with_pouting_face::skin-tone-5:": "1f64e-1f3fe",
      ":person_with_pouting_face::skin-tone-6:": "1f64e-1f3ff",
      ":person_with_pouting_face:": "1f64e",
      ":pray::skin-tone-2:": "1f64f-1f3fb",
      ":pray::skin-tone-3:": "1f64f-1f3fc",
      ":pray::skin-tone-4:": "1f64f-1f3fd",
      ":pray::skin-tone-5:": "1f64f-1f3fe",
      ":pray::skin-tone-6:": "1f64f-1f3ff",
      ":pray:": "1f64f",
      ":rocket:": "1f680",
      ":helicopter:": "1f681",
      ":steam_locomotive:": "1f682",
      ":railway_car:": "1f683",
      ":bullettrain_side:": "1f684",
      ":bullettrain_front:": "1f685",
      ":train2:": "1f686",
      ":metro:": "1f687",
      ":light_rail:": "1f688",
      ":station:": "1f689",
      ":tram:": "1f68a",
      ":train:": "1f68b",
      ":bus:": "1f68c",
      ":oncoming_bus:": "1f68d",
      ":trolleybus:": "1f68e",
      ":busstop:": "1f68f",
      ":minibus:": "1f690",
      ":ambulance:": "1f691",
      ":fire_engine:": "1f692",
      ":police_car:": "1f693",
      ":oncoming_police_car:": "1f694",
      ":taxi:": "1f695",
      ":oncoming_taxi:": "1f696",
      ":car:": "1f697",
      ":oncoming_automobile:": "1f698",
      ":blue_car:": "1f699",
      ":truck:": "1f69a",
      ":articulated_lorry:": "1f69b",
      ":tractor:": "1f69c",
      ":monorail:": "1f69d",
      ":mountain_railway:": "1f69e",
      ":suspension_railway:": "1f69f",
      ":mountain_cableway:": "1f6a0",
      ":aerial_tramway:": "1f6a1",
      ":ship:": "1f6a2",
      ":rowboat::skin-tone-2:": "1f6a3-1f3fb",
      ":rowboat::skin-tone-3:": "1f6a3-1f3fc",
      ":rowboat::skin-tone-4:": "1f6a3-1f3fd",
      ":rowboat::skin-tone-5:": "1f6a3-1f3fe",
      ":rowboat::skin-tone-6:": "1f6a3-1f3ff",
      ":rowboat:": "1f6a3",
      ":speedboat:": "1f6a4",
      ":traffic_light:": "1f6a5",
      ":vertical_traffic_light:": "1f6a6",
      ":construction:": "1f6a7",
      ":rotating_light:": "1f6a8",
      ":triangular_flag_on_post:": "1f6a9",
      ":door:": "1f6aa",
      ":no_entry_sign:": "1f6ab",
      ":smoking:": "1f6ac",
      ":no_smoking:": "1f6ad",
      ":put_litter_in_its_place:": "1f6ae",
      ":do_not_litter:": "1f6af",
      ":potable_water:": "1f6b0",
      ":non-potable_water:": "1f6b1",
      ":bike:": "1f6b2",
      ":no_bicycles:": "1f6b3",
      ":bicyclist::skin-tone-2:": "1f6b4-1f3fb",
      ":bicyclist::skin-tone-3:": "1f6b4-1f3fc",
      ":bicyclist::skin-tone-4:": "1f6b4-1f3fd",
      ":bicyclist::skin-tone-5:": "1f6b4-1f3fe",
      ":bicyclist::skin-tone-6:": "1f6b4-1f3ff",
      ":bicyclist:": "1f6b4",
      ":mountain_bicyclist::skin-tone-2:": "1f6b5-1f3fb",
      ":mountain_bicyclist::skin-tone-3:": "1f6b5-1f3fc",
      ":mountain_bicyclist::skin-tone-4:": "1f6b5-1f3fd",
      ":mountain_bicyclist::skin-tone-5:": "1f6b5-1f3fe",
      ":mountain_bicyclist::skin-tone-6:": "1f6b5-1f3ff",
      ":mountain_bicyclist:": "1f6b5",
      ":walking::skin-tone-2:": "1f6b6-1f3fb",
      ":walking::skin-tone-3:": "1f6b6-1f3fc",
      ":walking::skin-tone-4:": "1f6b6-1f3fd",
      ":walking::skin-tone-5:": "1f6b6-1f3fe",
      ":walking::skin-tone-6:": "1f6b6-1f3ff",
      ":walking:": "1f6b6",
      ":no_pedestrians:": "1f6b7",
      ":children_crossing:": "1f6b8",
      ":mens:": "1f6b9",
      ":womens:": "1f6ba",
      ":restroom:": "1f6bb",
      ":baby_symbol:": "1f6bc",
      ":toilet:": "1f6bd",
      ":wc:": "1f6be",
      ":shower:": "1f6bf",
      ":bath::skin-tone-2:": "1f6c0-1f3fb",
      ":bath::skin-tone-3:": "1f6c0-1f3fc",
      ":bath::skin-tone-4:": "1f6c0-1f3fd",
      ":bath::skin-tone-5:": "1f6c0-1f3fe",
      ":bath::skin-tone-6:": "1f6c0-1f3ff",
      ":bath:": "1f6c0",
      ":bathtub:": "1f6c1",
      ":passport_control:": "1f6c2",
      ":customs:": "1f6c3",
      ":baggage_claim:": "1f6c4",
      ":left_luggage:": "1f6c5",
      ":couch_and_lamp:": "1f6cb",
      ":sleeping_accommodation:": "1f6cc",
      ":shopping_bags:": "1f6cd",
      ":bellhop_bell:": "1f6ce",
      ":bed:": "1f6cf",
      ":place_of_worship:": "1f6d0",
      ":hammer_and_wrench:": "1f6e0",
      ":shield:": "1f6e1",
      ":oil_drum:": "1f6e2",
      ":motorway:": "1f6e3",
      ":railway_track:": "1f6e4",
      ":motor_boat:": "1f6e5",
      ":small_airplane:": "1f6e9",
      ":airplane_departure:": "1f6eb",
      ":airplane_arriving:": "1f6ec",
      ":satellite:": "1f6f0",
      ":passenger_ship:": "1f6f3",
      ":zipper_mouth_face:": "1f910",
      ":money_mouth_face:": "1f911",
      ":face_with_thermometer:": "1f912",
      ":nerd_face:": "1f913",
      ":thinking_face:": "1f914",
      ":face_with_head_bandage:": "1f915",
      ":robot_face:": "1f916",
      ":hugging_face:": "1f917",
      ":the_horns::skin-tone-2:": "1f918-1f3fb",
      ":the_horns::skin-tone-3:": "1f918-1f3fc",
      ":the_horns::skin-tone-4:": "1f918-1f3fd",
      ":the_horns::skin-tone-5:": "1f918-1f3fe",
      ":the_horns::skin-tone-6:": "1f918-1f3ff",
      ":the_horns:": "1f918",
      ":crab:": "1f980",
      ":lion_face:": "1f981",
      ":scorpion:": "1f982",
      ":turkey:": "1f983",
      ":unicorn_face:": "1f984",
      ":cheese_wedge:": "1f9c0",
      ":double_exclamation_mark:": "203c",
      ":interrobang:": "2049",
      ":trade_mark:": "2122",
      ":information_source:": "2139",
      ":left_right_arrow:": "2194",
      ":arrow_up_down:": "2195",
      ":arrow_upper_left:": "2196",
      ":arrow_upper_right:": "2197",
      ":arrow_lower_right:": "2198",
      ":arrow_lower_left:": "2199",
      ":leftwards_arrow_with_hook:": "21a9",
      ":arrow_right_hook:": "21aa",
      ":hash:": "23-20e3",
      ":watch:": "231a",
      ":hourglass:": "231b",
      ":keyboard:": "2328",
      ":eject:": "23cf",
      ":fast_forward:": "23e9",
      ":rewind:": "23ea",
      ":arrow_double_up:": "23eb",
      ":arrow_double_down:": "23ec",
      ":black_right_pointing_double_triangle_with_vertical_bar:": "23ed",
      ":black_left_pointing_double_triangle_with_vertical_bar:": "23ee",
      ":black_right_pointing_triangle_with_double_vertical_bar:": "23ef",
      ":alarm_clock:": "23f0",
      ":stopwatch:": "23f1",
      ":timer_clock:": "23f2",
      ":hourglass_flowing_sand:": "23f3",
      ":double_vertical_bar:": "23f8",
      ":black_square_for_stop:": "23f9",
      ":black_circle_for_record:": "23fa",
      ":m_circled:": "24c2",
      ":black_small_square:": "25aa",
      ":white_small_square:": "25ab",
      ":arrow_forward:": "25b6",
      ":arrow_backward:": "25c0",
      ":white_medium_square:": "25fb",
      ":black_medium_square:": "25fc",
      ":white_medium_small_square:": "25fd",
      ":black_medium_small_square:": "25fe",
      ":sunny:": "2600",
      ":cloud:": "2601",
      ":umbrella:": "2602",
      ":showman:": "2603",
      ":comet:": "2604",
      ":phone:": "260e",
      ":ballot_box_with_check:": "2611",
      ":umbrella_with_rain_drops:": "2614",
      ":coffee:": "2615",
      ":shamrock:": "2618",
      ":point_up::skin-tone-2:": "261d-1f3fb",
      ":point_up::skin-tone-3:": "261d-1f3fc",
      ":point_up::skin-tone-4:": "261d-1f3fd",
      ":point_up::skin-tone-5:": "261d-1f3fe",
      ":point_up::skin-tone-6:": "261d-1f3ff",
      ":point_up:": "261d",
      ":skull_and_crossbones:": "2620",
      ":radioactive_sign:": "2622",
      ":biohazard_sign:": "2623",
      ":orthodox_cross:": "2626",
      ":star_and_crescent:": "262a",
      ":peace_symbol:": "262e",
      ":yin_yang:": "262f",
      ":wheel_of_dharma:": "2638",
      ":white_frowning_face:": "2639",
      ":relaxed:": "263a",
      ":aries:": "2648",
      ":taurus:": "2649",
      ":gemini:": "264a",
      ":cancer:": "264b",
      ":leo:": "264c",
      ":virgo:": "264d",
      ":libra:": "264e",
      ":scorpius:": "264f",
      ":sagittarius:": "2650",
      ":capricorn:": "2651",
      ":aquarius:": "2652",
      ":pisces:": "2653",
      ":spades:": "2660",
      ":clubs:": "2663",
      ":hearts:": "2665",
      ":diamonds:": "2666",
      ":hotsprings:": "2668",
      ":recycle:": "267b",
      ":wheelchair:": "267f",
      ":hammer_and_pick:": "2692",
      ":anchor:": "2693",
      ":crossed_swords:": "2694",
      ":scales:": "2696",
      ":alembic:": "2697",
      ":gear:": "2699",
      ":atom_symbol:": "269b",
      ":fleur_de_lis:": "269c",
      ":warning:": "26a0",
      ":zap:": "26a1",
      ":white_circle:": "26aa",
      ":black_circle:": "26ab",
      ":coffin:": "26b0",
      ":funeral_urn:": "26b1",
      ":soccer:": "26bd",
      ":baseball:": "26be",
      ":snowman:": "26c4",
      ":partly_sunny:": "26c5",
      ":thunder_cloud_and_rain:": "26c8",
      ":ophiuchus:": "26ce",
      ":pick:": "26cf",
      ":helmet_with_white_cross:": "26d1",
      ":chains:": "26d3",
      ":no_entry:": "26d4",
      ":shinto_shrine:": "26e9",
      ":church:": "26ea",
      ":mountain:": "26f0",
      ":umbrella_on_ground:": "26f1",
      ":fountain:": "26f2",
      ":golf:": "26f3",
      ":ferry:": "26f4",
      ":boat:": "26f5",
      ":skier:": "26f7",
      ":ice_skate:": "26f8",
      ":person_with_ball::skin-tone-2:": "26f9-1f3fb",
      ":person_with_ball::skin-tone-3:": "26f9-1f3fc",
      ":person_with_ball::skin-tone-4:": "26f9-1f3fd",
      ":person_with_ball::skin-tone-5:": "26f9-1f3fe",
      ":person_with_ball::skin-tone-6:": "26f9-1f3ff",
      ":person_with_ball:": "26f9",
      ":tent:": "26fa",
      ":fuelpump:": "26fd",
      ":scissors:": "2702",
      ":white_check_mark:": "2705",
      ":airplane:": "2708",
      ":email:": "2709",
      ":fist::skin-tone-2:": "270a-1f3fb",
      ":fist::skin-tone-3:": "270a-1f3fc",
      ":fist::skin-tone-4:": "270a-1f3fd",
      ":fist::skin-tone-5:": "270a-1f3fe",
      ":fist::skin-tone-6:": "270a-1f3ff",
      ":fist:": "270a",
      ":hand::skin-tone-2:": "270b-1f3fb",
      ":hand::skin-tone-3:": "270b-1f3fc",
      ":hand::skin-tone-4:": "270b-1f3fd",
      ":hand::skin-tone-5:": "270b-1f3fe",
      ":hand::skin-tone-6:": "270b-1f3ff",
      ":hand:": "270b",
      ":victory_hand::skin-tone-2:": "270c-1f3fb",
      ":victory_hand::skin-tone-3:": "270c-1f3fc",
      ":victory_hand::skin-tone-4:": "270c-1f3fd",
      ":victory_hand::skin-tone-5:": "270c-1f3fe",
      ":victory_hand::skin-tone-6:": "270c-1f3ff",
      ":victory_hand:": "270c",
      ":writing_hand::skin-tone-2:": "270d-1f3fb",
      ":writing_hand::skin-tone-3:": "270d-1f3fc",
      ":writing_hand::skin-tone-4:": "270d-1f3fd",
      ":writing_hand::skin-tone-5:": "270d-1f3fe",
      ":writing_hand::skin-tone-6:": "270d-1f3ff",
      ":writing_hand:": "270d",
      ":pencil2:": "270f",
      ":black_nib:": "2712",
      ":heavy_check_mark:": "2714",
      ":heavy_multiplication_x:": "2716",
      ":latin_cross:": "271d",
      ":star_of_david:": "2721",
      ":sparkles:": "2728",
      ":eight_spoked_asterisk:": "2733",
      ":eight_pointed_black_star:": "2734",
      ":snowflake:": "2744",
      ":sparkle:": "2747",
      ":cross_mark:": "274c",
      ":negative_squared_cross_mark:": "274e",
      ":question:": "2753",
      ":grey_question:": "2754",
      ":grey_exclamation:": "2755",
      ":exclamation:": "2757",
      ":heavy_heart_exclamation_mark_ornament:": "2763",
      ":heart:": "2764",
      ":heavy_plus_sign:": "2795",
      ":heavy_minus_sign:": "2796",
      ":heavy_division_sign:": "2797",
      ":arrow_right:": "27a1",
      ":curly_loop:": "27b0",
      ":loop:": "27bf",
      ":arrow_heading_up:": "2934",
      ":arrow_heading_down:": "2935",
      ":asterisk:": "2a-20e3",
      ":arrow_left:": "2b05",
      ":arrow_up:": "2b06",
      ":arrow_down:": "2b07",
      ":black_large_square:": "2b1b",
      ":white_large_square:": "2b1c",
      ":star:": "2b50",
      ":large_circle:": "2b55",
      ":zero:": "30-20e3",
      ":wavy_dash:": "3030",
      ":part_alternation_mark:": "303d",
      ":one:": "31-20e3",
      ":two:": "32-20e3",
      ":congratulations:": "3297",
      ":secret:": "3299",
      ":three:": "33-20e3",
      ":four:": "34-20e3",
      ":five:": "35-20e3",
      ":six:": "36-20e3",
      ":seven:": "37-20e3",
      ":eight:": "38-20e3",
      ":nine:": "39-20e3",
      ":copyright:": "a9",
      ":registered_sign:": "ae",
      ":shibuya:": "e50a",
    }
  }

}