import Messages from '../models/messages.model';
import SdkOptions from '../models/options.sdk.model';
import Platform from '../services/platform.service';
import User from '../classes/user.class';
import isUndefined from "lodash/isUndefined";
import { AxiosPromise } from 'axios';
import sanitizeHtml from 'sanitize-html';
export default class Sdk {

  public server?: Platform;
  public user?: User;
  public messages?: Messages;
  public options: SdkOptions;
  public apiToken?: string;
  private nextMetadata: any = {};

  constructor(options: any) {
    this.apiToken = isUndefined(options.apiToken) ? undefined : options.apiToken;
    this.options = new SdkOptions(options);
    if(!isUndefined(this.apiToken)){
      this.instance(options);
      if(options.autoStart){
        this.start();
      }
    }
  }

  public instance(options?:any){

    this.user = new User(this.options.cname + '-' + this.apiToken,
                                     this.options.clientId,
                                     this.options.domain,
                                     this.options.singleUse);

    this.messages = new Messages(undefined, this.user, this.user.userUniqId);

    this.server = new Platform(this.options,
                               this.messages,
                               this.user.client);
  }



  public send(text: string, sensitive_information?: any, metadata?: any)
              : AxiosPromise | undefined {
    if(this.user && this.server){
      this.user.client.hasInteract = true;
      this.user.save();
      this.start();
      let clean = sanitizeHtml(text);
      return this.server.send(clean, sensitive_information, this.mergeMetadata(metadata));
    }
  }

  public sendNextToBot(metadata: any) {
    this.nextMetadata.custom_properties = metadata;
  }

  public sendNextToDesk(metadata: any) {
    this.nextMetadata.chat_metadata = metadata;
  }

  // a action method alias
  public payload(bot_id: string,
                 action: string,
                 channel_identifier?: string) : AxiosPromise | undefined {
    let clean = sanitizeHtml(action);
    return this.action(bot_id, clean, channel_identifier);
  }

  public action(bot_id: string,
                action: string,
                channel_identifier?: string) : AxiosPromise | undefined {
    if(this.server){
      let clean = sanitizeHtml(action);
      return this.server.api.action(bot_id, clean, channel_identifier);
    }
  }

  public isConnected(){
    if(this.server){
      return isUndefined(this.server.cable.channel) ? this.server.pool.isPoolingStarted :
                  (this.server.pool.isPoolingStarted || this.server.cable.channel.connected);
    }
  }

  public start(force?:boolean) {
    if(!this.isConnected()){
      if(this.user && this.server){
        if (this.user.client.hasInteract || force) {
          this.server.api.load(this.server.pool.lastMessageId).then((request: any) => {
            if(this.server){
              this.server.pool.receive(request.data);
              if(this.server.shouldStartCable){
                this.server.cable.create( () => this.cableConnected(), () => this.cableDisconnected(), (m:any) => this.cableReceived(m));
              } else {
                this.server.pool.start(force);
              }
            }
          });
        }
      }
    }
  }

  private mergeMetadata(metadata: any) {
    metadata = metadata ? metadata : {};
    metadata.custom_properties = this.nextMetadata.custom_properties;
    metadata.chat_metadata = this.nextMetadata.chat_metadata;
    return metadata;
  }

  private cableConnected (){
    if(this.server){
      this.server.pool.stop()
    }
  }

  private cableDisconnected () {
    if(this.server){
      this.server.pool.start();
    }
  }

  private cableReceived(m:any){
    if(m.action === "new-message-alert" && this.server){
      this.server.api.load(this.server.pool.lastMessageId).then((request: any) => {
        if(this.server){
          this.server.pool.receive(request.data);
        }
      });
    }
  }
}
