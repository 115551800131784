import isUndefined from "lodash/isUndefined";
import 'reflect-metadata';

export default class SdkOptions{

  public serverUrl:string;
  public websocketUrl:string;
  public apiToken:string;
  // pooling
  public poolingTimeout:any;
  public poolingStep:any;
  public poolingLimit:any;
  // others
  public clientId:string;
  public autoStart:boolean;
  public singleUse:boolean;
  public cname:string;
  public domain:string;

  constructor(options:any){
    this.apiToken = options.apiToken;
    this.websocketUrl = options.websocketUrl;
    this.domain = options.domain;
    this.clientId = options.clientId;

    this.cname = options.cname || "nama-sdk-user";
    this.serverUrl =  options.serverUrl || 'https://app.nama.ai';
    this.poolingTimeout = parseInt(options.poolingTimeout, 10) || 1000;
    this.poolingStep = parseInt(options.poolingStep, 10) || 2000;
    this.poolingLimit = parseInt(options.poolingLimit, 10) || 40000;

    this.singleUse = isUndefined(options.singleUse) ? false : options.singleUse;
    this.autoStart = isUndefined(options.autoStart) ? true : options.autoStart ;
  }
}
