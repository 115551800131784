import isUndefined from "lodash/isUndefined";
import sanitizeHtml from 'sanitize-html';
import moment from 'moment';
export default class Message {

  public id: string;
  public chat_id: number;
  public created_at: string;
  public text: string;
  public from: string;
  public type: string;
  public metadata: any;
  public is_temp: boolean;
  public is_off: boolean;
  public is_system: boolean;
  public sent_by_user: boolean;
  public sent_by_operator: boolean;
  public triggers: Array<any>;
  public triggered: boolean;
  public choices: Array<any>;
  public cards: Array<any>;
  public visualized: boolean;
  public notified: boolean;


  constructor(message: any) {
    this.id = message.id + "";
    this.chat_id = message.chat_id || 0;
    this.is_temp = isUndefined(message.is_temp) ? false : message.is_temp;
    this.is_off = message.is_off;
    this.created_at = moment(message.created_at).format();
    let clean = sanitizeHtml(message.text);
    this.text = clean;
    this.from = message.from;
    this.metadata = message.metadata || {};
    this.sent_by_user = message.sent_by_user;
    this.sent_by_operator = typeof this.metadata.operator_name === "string";
    this.triggers = (message.metadata) ? message.metadata.triggers || undefined : undefined;
    this.triggered = message.triggered || false;
    this.choices = message.choices || [];
    this.cards = typeof this.metadata.card_group !== "undefined" ? this.metadata.card_group.cards : [];
    this.type = this.whichType(message);
    this.is_system = isUndefined(message.is_system) ? false : message.is_system;
    this.visualized = message.visualized || false;
    this.notified = message.notified || false;
  }

  private whichType(message: any):string {
    let type;
    if(this.sent_by_user) {
      type = 'user';
    } else {
      type = (this.sent_by_operator ? 'operator' : 'bot');
    }
    if(!isUndefined(message.type)){
      type = message.type;
    }
    if(message.metadata){
      if(message.metadata.system_message){
        type = "system";
      }
      if(message.metadata.sent_by_operator){
        type = "operator";
      }
    }
    return type;
  }
}
